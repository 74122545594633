import { useState } from 'react';

const useStatus = () => {
    const [isOpen, setIsOpen] = useState(false);
    
    const open = () => {
        setIsOpen(true);
    };
    
    const close = (e, reason) => {
        if (reason !== "backdropClick") {
            setIsOpen(false);
        }
    };
    
    return {
        status: {
            open,
            isOpen,
            close
        }
    }
};

export default useStatus;