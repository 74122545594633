import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { ExpandedTableHeader } from '../../components/ExpandedTableHeader';
import { ExpandedTableRow } from '../../components/ExpandedTableRow';
import { Search } from '../../components/Search';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useSdk from '../../hooks/useSdk';
import './index.css';
import 'jsoneditor/dist/jsoneditor.css';
import { goToLogoutInit, goToTemplatesWithStatus } from '../../store/nav';

const templateTabs = [
    { label: "Active" },
    { label: "Approved" },
    { label: "Review" },
    { label: "Draft" }
];

const Templates = ({ 
    dialog, 
    status, 
    LOCALE,
    clientId,
    roles
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [dataType, setDataType] = useState(0);
    const [activeData, setActiveData] = useState([]);
    const [draftData, setDraftData] = useState([]);
    const [approvedData, setApprovedData] = useState([]);
    const [reviewData, setReviewData] = useState([]);
    const [dataObj, setDataObj] = useState([]);
    const [searchTerm, setSearchTerm] = useState();

    const [sortDir, setSortDir] = useState("ASC");
    const [sortDirName, setSortDirName] = useState("");

    const { sdk, sdkInitialized } = useSdk();

    useEffect(() => {
        loadTab();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, searchTerm, sortDir, activeData, draftData, approvedData, reviewData]);

    useEffect(() => {
        if (sdkInitialized) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId, sdkInitialized]);

    const loadTab = () => {
        const pathArray = location.pathname.split('/');
        const status = pathArray[pathArray.length - 1];
        let idx = templateTabs.findIndex(t => t.label.toLowerCase() === status);
        if (idx < 0) {
            idx = 0;
        }
        setDataType(idx);
        switch(status) {
            case 'active': 
                const filteredActive = removeDuplicates(activeData);
                setDataObj(filteredActive);
                break; // Active
            case 'approved': 
                const filteredApproved = removeDuplicates(approvedData);
                setDataObj(filteredApproved);
                break; // Approved
            case 'review': 
                const filteredReview = removeDuplicates(reviewData);
                setDataObj(filteredReview);
                break; // Review
            case 'draft': 
                const filteredDrafts = removeDuplicates(draftData);
                setDataObj(filteredDrafts);
                break; // Draft
            default:
                const defaultFilteredActive = removeDuplicates(activeData);
                setDataObj(defaultFilteredActive);
                break; // Active
        }
    }

    const removeDuplicates = (arr) => {
        arr.sort((a, b) => parseInt(a.templateVersion) < parseInt(b.templateVersion) ? 1 : -1);
        const list = arr.filter((value, index, self) => {
            return index === self.findIndex((t) => (
                t.templateName === value.templateName
            ))
        });

        return filterSearch(list);
    }

    const filterSearch = (list) => {
        if (searchTerm) {
            return list.filter(d => d.templateName.toLowerCase().includes(searchTerm.toLowerCase())).sort(sortByName);
        } else {
            return list.sort(sortByName);
        }
    }

    const sortByName = (a, b) => {
        if (sortDir === "ASC") {
            return (a[sortDirName]?.toString().toLowerCase() > b[sortDirName]?.toString().toLowerCase()) ? 0 : -1;
        }
        else {
            return (a[sortDirName]?.toString().toLowerCase() < b[sortDirName]?.toString().toLowerCase()) ? 0 : -1;
        }
    }

    const getData = async () => {
        try {
            status.open();

            const { data: { templateDataSet: allDataList } } = await sdk.templateStatusListClientidStatusGet({ clientid: clientId, status: 'ALL' });
            setActiveData(allDataList.filter(d => d.templateStatus === 'ACTIVE'));
            setDraftData(allDataList.filter(d => d.templateStatus === 'DRAFT'));
            setApprovedData(allDataList.filter(d => d.templateStatus === 'APPROVED'));
            setReviewData(allDataList.filter(d => d.templateStatus === 'REVIEW'));

            status.close();
        }
        catch (e) {
            console.log("Exception: ", e);
            goToLogoutInit();
        }
    }
      
    const columns = [
        { field: 'templateName', headerName: LOCALE.TEMPLATE.TEMPLATE_NAME, flex: 1, sort: true },
        { field: 'author', headerName: LOCALE.TEMPLATE.AUTHOR, flex: 1, sort: true },
        { field: 'templateVersion', headerName: LOCALE.TEMPLATE.VERSION, flex: 1 },
        { field: 'templateState', headerName: 'Status', flex: 1 },
        { field: 'timestamp', headerName: LOCALE.TEMPLATE.CREATED, sort: true },
        { field: 'actions', headerName: '', flex: 1 }
    ];

    const setDataGrouping = (e, newValue) => {
        goToTemplatesWithStatus(navigate, templateTabs[newValue].label.toLowerCase());
        setDataType(newValue);
    }

    return (
        <>
            <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={'tabs'}>
                    <Tabs value={dataType} onChange={setDataGrouping} aria-label="Data Tabs">
                        {templateTabs.map((t, idx) => <Tab key={`tab_${idx}`} label={t.label} />)}
                    </Tabs>
                </Box>
                <div className={'search-container'}>
                    <Search
                        label={LOCALE.TEMPLATE.SEARCH}
                        value={searchTerm}
                        onChange={ev => setSearchTerm(ev.target.value)}
                    />
                </div>
            </div>
            <div className={'content'}>
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <ExpandedTableHeader reviewColumn={2} {...{columns, dataType, sortDir, setSortDir, sortDirName, setSortDirName}} />
                            {dataObj.map((d, idx) => <ExpandedTableRow 
                                key={`row_${idx}`}
                                getData={getData}
                                clientId={clientId} 
                                sdk={sdk} 
                                data={d} 
                                LOCALE={LOCALE} 
                                dialog={dialog} 
                                roles={roles} 
                                status={status}
                                type={'template'}
                                showExpand={dataType !== 2}
                                activeData={activeData}
                                draftData={draftData}
                                approvedData={approvedData}
                                reviewData={reviewData}
                                ></ExpandedTableRow>)}
                        </Table>
                    </TableContainer>
                </div>

            </div>
        </>
    );
}

export default Templates;