import React, { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';
import DownloadIcon from '@mui/icons-material/Download';
import ReportIcon from '@mui/icons-material/List';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Picker } from '../../components/Picker';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useSdk from '../../hooks/useSdk';
import './index.css';
import 'jsoneditor/dist/jsoneditor.css';
import moment from 'moment';


const typePicker = [
    {name: 'Asset', id: 'Asset'}, 
    {name: 'Template', id: 'Template'}, 
    {name: 'User', id: 'User'}
];

const Reports = ({ 
    dialog,
    status,
    LOCALE,
    clientId
}) => {
    
    const [reportName, setReportName] = useState('');
    const [reportUser, setReportUser] = useState('');
    const [reportType, setReportType] = useState('');
    const [reportServerType, setReportServerType] = useState('');
    const [reportServerTypeLabel, setReportServerTypeLabel] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [selectedArtifact, setSelectedArtifact] = useState('');
    const [artifactData, setArtifactData] = useState([]);

    const [data, setData] = useState([]);

    const { sdk, sdkInitialized } = useSdk();

    useEffect(() => {
        if (sdkInitialized) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId, sdkInitialized]);

    useEffect(() => {
        if (reportType === "Asset" || reportType === "Template") {
            setReportServerType("Artifact");
            setReportServerTypeLabel(`${reportType} Name`);
            setSelectedArtifact('');

            if (reportType === "Template") {
                getTemplateData();
            } else if (reportType === "Asset") {
                getAssetData();
            }
        }
        else if (reportType === "User") {
            setReportServerType("User");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType]);
    
    const getTemplateData = async () => {
        try {
            status.open();
            const { data: { templateDataSet: allTemplatesDataList } } = await window.sdk.templateStatusListClientidStatusGet({ clientid: clientId, status: 'ALL' });

            const unfilteredArtifacts = allTemplatesDataList.map(t => ({label: t.templateName, name: t.templateName, id: t.templateName}));

            const artifacts = unfilteredArtifacts.filter((value, index, self) => {
                return index === self.findIndex((t) => t.label === value.label)
            });

            if (artifacts.length === 0) {
                setArtifactData([LOCALE.REPORTING.NO_TEMPLATE_DATA]);
              } else { 
                setArtifactData(artifacts.sort(sortByName));
            }

            status.close();
        }
        catch (e) {
            status.close();
        }
    }
    
    const getAssetData = async () => {
        try {
            status.open();
            const { data } = await sdk.assetListClientidGet({ clientId });
            const { assetsMetaData } = data;

            const assets = [];
            for (let i in assetsMetaData) {
                assets.push({label: i, name: i, id: i});
            }

            if (assets.length === 0) {
                setArtifactData([LOCALE.REPORTING.NO_ASSET_DATA]);
              } else { 
                setArtifactData(assets.sort(sortByName));
            }

            status.close();
        }
        catch (e) {
            status.close();
        }
    }

    const sortByName = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        return 0;
    }

    const getDate = (value) => {
        return moment(value).format('LLL');
    }

    const handleChangeReportType = ({target: { value }}) => {
        setReportType(value);
    };

    const handleChangeStartDate = (value) => {
        setStartDate(value);
    };

    const handleChangeEndDate = (value) => {
        setEndDate(value);
    };

    const handleChangeSelectedArtifact = ({target: { textContent }}) => {
        setSelectedArtifact(textContent);
    };

    const createReport = async () => {
        status.open();
        let tempStartDate = startDate.date();
        let tempEndDate = endDate.date();
        tempStartDate = tempStartDate.toString().length === 1 ? `0${tempStartDate}` : tempStartDate;
        tempEndDate = tempEndDate.toString().length === 1 ? `0${tempEndDate}` : tempEndDate;

        let tempStartMonth = startDate.month() + 1;
        let tempEndMonth = endDate.month() + 1;
        tempStartMonth = tempStartMonth.toString().length === 1 ? `0${tempStartMonth}` : tempStartMonth;
        tempEndMonth = tempEndMonth.toString().length === 1 ? `0${tempEndMonth}` : tempEndMonth;

        const body = {
            startDate: `${startDate.year()}-${tempStartMonth}-${tempStartDate}`,
            endDate: `${endDate.year()}-${tempEndMonth}-${tempEndDate}`,
            reportName,
            reportType: reportServerType,
            clientZoneOffset: '-06:00'
        }

        if (reportType === "User" && reportUser) {
            body.searchEmail = reportUser;
        }

        if (reportType === "Template" || reportType === "Asset") {
            body.artifactName = selectedArtifact;
        }

        await sdk.reportCreateClientidPost({ clientid: clientId }, body);
        getData();
        status.close();
        showCreateSuccess();
        resetForm();
    }

    const showCreateSuccess = () => {
        dialog.setTitle(LOCALE.REPORTING.SUCCESS_TITLE);
        dialog.setText(LOCALE.REPORTING.TEXT);
        dialog.setYesText(LOCALE.GLOBAL.OK);
        dialog.setNoText('');
        dialog.setCls('');
        dialog.setIcon('');
        dialog.setRenderComponentsMethod(() => {});
        dialog.open();
    }

    const resetForm = () => {
        setReportName('');
        setReportType('');
        setReportUser('');
        setStartDate('');
        setEndDate('');
        setReportType('');
        setSelectedArtifact('');
    }

    const disableForm = () => {
        return reportType === 'User' ? (!reportName || !reportUser || !startDate || !endDate || startDate > endDate)
         : (!reportName || !selectedArtifact || !startDate || !endDate || startDate > endDate);
    }

    const getData = async () => {
        try {
            
            status.open();
            const { data } = await sdk.reportListClientidGet({ clientid: clientId, clientZoneOffset: '-06:00' });
            const { reportList } = data;
            setData(reportList);

            status.close();
        }
        catch (e) {
            status.close();
        }
    }

    const downloadReport = async (reportId) => {
        try {
            if (reportId) {
                status.open();
                const { data } = await sdk.reportRetrieveClientidGet({ clientid: clientId, reportFile: reportId });
                download(reportId, data);

                status.close();
            }
        }
        catch (e) {
            status.close();
        }
    }

    const refreshReports = () => {
        getData();
    }
      
    const columns = [
        { field: 'reportName', headerName: 'Report Name', flex: 1 },
        { field: 'lastModified', headerName: 'Created', flex: 1 },
        { field: 'actions', headerName: '', flex: 1 }
    ];
    
    const download = function (reportName, data) {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', reportName);
        a.click()
    }

    return (
        <>
            <div>
                <Grid spacing={2} container xs={12} md={12} lg={12} className={'report-param-container'}>
                    <Grid xs={12} md={12} lg={6}>
                        <div>
                            <TextField
                                label={'Report Name'}
                                id="report-name"
                                value={reportName}
                                style={{width: '100%'}}
                                onChange={ev => setReportName(ev.target.value)}
                                />
                        </div>
                        <br />
                        <div>
                            <Picker 
                                label={'Report Type'}
                                items={typePicker} 
                                value={reportType}
                                action={handleChangeReportType} />
                        </div>
                        {reportType && 
                            <>
                                <br />
                                <div>
                                    {reportType !== "User" ? <Autocomplete
                                        freeSolo
                                        id="combo-box-demo"
                                        options={artifactData}
                                        value={selectedArtifact}
                                        renderInput={(params) => <TextField {...params} onChange={e => setSelectedArtifact(e.target.value)} label={reportServerTypeLabel} />}
                                        onChange={handleChangeSelectedArtifact} />
                                        : 
                                    <TextField
                                        label={'Report User'}
                                        id="report-user"
                                        value={reportUser}
                                        style={{ width: '100%' }}
                                        onChange={ev => setReportUser(ev.target.value)} />
                                        }
                                </div>
                            </>
                        }
                        <br />
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label={LOCALE.REPORTING.START_DATE_LABEL} value={startDate} onChange={handleChangeStartDate} slotProps={disableForm() ? { textField: { helperText: LOCALE.REPORTING.START_DATE_HELPER}} : ''} />
                                    <DatePicker label={LOCALE.REPORTING.END_DATE_LABEL} value={endDate} onChange={handleChangeEndDate} slotProps={disableForm() ? { textField: { helperText: LOCALE.REPORTING.END_DATE_HELPER}} : ''} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <br />
                        <div>
                            <Button variant={'contained'} className='grid-button' onClick={createReport} disabled={disableForm()}>
                                <ReportIcon className={'icon'} />
                                Create Report
                            </Button>
                            <Button variant={'contained'} className='grid-button' onClick={refreshReports}>
                                <RefreshIcon className={'icon'} />
                                Refresh Reports
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={'content'}>
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead sx={{ '& > *': { borderBottom: 'unset' } }}>
                                {columns.map((c, idx) => {
                                    return (
                                        <TableCell component="th" scope="row" key={`cell_${idx}`}>
                                            {c.headerName}
                                        </TableCell>
                                    );
                                })}
                            </TableHead>
                            {data.map(d => <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell>
                                    {d.reportName}
                                </TableCell>
                                <TableCell>
                                    {getDate(d.lastModified)}
                                </TableCell>
                                <TableCell align="right">
                                    <Button variant={'contained'} className='grid-button' onClick={(e) => downloadReport(d.reportName)}>
                                        <DownloadIcon className={'icon'} />
                                        {LOCALE.REPORTING.DOWNLOAD}
                                    </Button>
                                </TableCell>
                            </TableRow>)}
                        </Table>
                    </TableContainer>
                </div>

            </div>
        </>
    );
}

export default Reports;