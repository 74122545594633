//TODO: file has become too large, break into smaller, reusable components
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import prettier from 'prettier/standalone';
import parserHtml from 'prettier/parser-html';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import BlockIcon from '@mui/icons-material/Block';
import ListIcon from '@mui/icons-material/List';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Bee from "@mailupinc/bee-plugin";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment';
import JSONEditor from 'jsoneditor';
import { goToAssetEdit, goToAssetEditReadOnly, goToEdit, goToEditReadOnly, goToLoginInit } from '../../store/nav';
import {
    isValidJSON,
    isValidEmail,
    isAlphaNumeric,
    isAlphaWithUnderscore,
    isAlphaWithHyphenUnderscore,
    warningIcon
} from '../../store/util';
import { getTemplate, startEditor, updateHTML, refreshBeeAuth } from '../../services/bee';
import useSdk from '../../hooks/useSdk';
import './index.css';
import {
    goToTemplates,
    goToShared
} from '../../store/nav';

import mergeTagsJson from '../../store/mergeTags.json'
import customFontsJson from '../../store/customFonts.json'
import { ROLES } from '../../store/roles';
import { useLocation, useNavigate } from 'react-router-dom';

const blankJson = {
    key: '',
    value: ''
};

const REVIEW_STATUS = 'REVIEW';


const Create = ({
    drawer,
    status,
    dialog,
    LOCALE,
    sessionId,
    clientId,
    author,
    roles
}) => {
    let dialogTextInput = '';
    let maxAssetVersion = 0;
    const refreshToken = useRef({});
    const allowAutoSave = useRef(false); //Using useRef rather than a vanilla JS variable to reduce re-render side effects
    const trackJsonChanges = useRef();
    const isCommentRequired = useRef(false);
    const loadedTempMetaData = useRef([]);
    const { templateName, templateVersion, templateStatus, assetName, assetVersion, assetStatus } = useParams();
    const [searchParams] = useSearchParams();
    const isReadOnly = searchParams.get("readonly");
    const [reviewTemplates, setReviewTemplates] = useState([]);
    const [reviewAssets, setReviewAssets] = useState([]);
    const [approvedTemplates, setApprovedTemplates] = useState([]);
    const [activeTemplates, setActiveTemplates] = useState([]);
    const [draftTemplates, setDraftTemplates] = useState([]);
    const [token, setToken] = useState('');
    const [tempName, setTempName] = useState(templateName);
    const [editor, setEditor] = useState();
    const [templateAffectedList, setTemplateAffectedList] = useState([]);
    const [isEditing] = useState(templateName);
    const [hasChanged, setHasChanged] = useState(false);
    const [versionList, setVersionList] = useState();
    const [templateMetaData, setTemplateMetaData] = useState();
    const [fromNameField, setFromNameField] = useState(LOCALE.TEMPLATE.PROPERTIES.DEFAULTS.FROM_NAME);
    const [fromField, setFromField] = useState(LOCALE.TEMPLATE.PROPERTIES.DEFAULTS.FROM_EMAIL_LOCAL);

    const [replyToField, setReplyToField] = useState(LOCALE.TEMPLATE.PROPERTIES.DEFAULTS.REPLY_TO);
    const [subjectField, setSubjectField] = useState(LOCALE.TEMPLATE.PROPERTIES.DEFAULTS.SUBJECT);
    const [rowJson, setRowJson] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [isBeePreview, setIsBeePreview] = useState(false);
    const [showPreviewHtml, setShowPreviewHtml] = useState(false);
    const [isPropertyOpen, setIsPropertyOpen] = useState(false);
    const [editorContainer, setEditorContainer] = useState();
    const [tabValue, setTabValue] = useState(0);
    const [rawHtml, setRawHtml] = useState('');
    const [jsonEditor, setJsonEditor] = useState();
    const [autoSaveTime, setAutoSaveTime] = useState('');

    const [showTestSend, setShowTestSend] = useState(false);
    const [toEmail, setToEmail] = useState('');

    const [propList, setPropList] = useState([]);
    const [itemName, setItemName] = useState('');

    const navigate = useNavigate();
    const location = useLocation();


    useSdk();

    useEffect(() => {
        status.open();
        getAppToken();

        return () => {
            if (hasChanged) {
                openNavigateAwayDialog();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (token && editor) {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, editor]);

    useEffect(() => {
        if (templateMetaData) {
            const { ambientData, fromEmailLocalPart, fromName, replyTo, subject } = templateMetaData;
            setPropList(ambientData);
            setReplyToField(replyTo);
            setSubjectField(subject);
            setFromNameField(fromName);
            setFromField(fromEmailLocalPart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateMetaData]);

    useEffect(() => {
        dialog.setYesCallback(async () => {
            if (!isAlphaWithHyphenUnderscore(itemName)) {
                return false;
            }
            
            if (templateName) {
                const tempJsonObj = await fetchTempDesignJson(tempName, templateVersion);
                var parsedJson = JSON.parse(tempJsonObj);
                var tempHtml = await generateBeeHtml(parsedJson);
                SaveAsTemplateFeature(itemName, tempJsonObj, tempHtml.data, JSON.stringify(templateMetaData));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemName]);

    useEffect(() => {
        if (allowAutoSave.current || templateMetaData) {
            loadedTempMetaData.current = JSON.stringify({
                "subject": subjectField,
                "fromEmailLocalPart": fromField,
                "replyTo": replyToField,
                "fromName": fromNameField,
                "ambientData": [...propList]
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateMetaData, subjectField, fromNameField, fromField, replyToField, propList]);

    const getAssetMetaDataFromSdk = async () => {

        //Coupling consecutive 'await' calls will stop the loading spinner from disappearing when loading an asset
        try {
            status.open();
            const { data } = await window.sdk.assetListClientidGet({ clientId });
            const { rowJson, pageJson } = await getAssetFromSdk(assetName, assetVersion);
            const versions = await formatAssetSelection(assetName);
            const { assetsMetaData } = data;
            setTemplateAffectedList(assetsMetaData[assetName]);
            status.close();

            //Returning an object to store necessary values for loading an asset
            return {
                assetJSON: pageJson,
                assetVersions: versions
            };
        }
        catch (e) {

        }
    };

    const getAssetFromSdk = async (assetName, editor) => {
        const { data } = await window.sdk.assetRetrieveClientidTemplatenameGet({ clientId, templatename: `${assetName}-${assetVersion}` });
        const { assetDataSet } = data;
        return assetDataSet[0];
    };

    const getTemplateFromSdk = async (tempName, tempVersion, editor) => {
        const { data } = await window.sdk.templateRetrieveClientidTemplatenameGet({ clientId, templatename: `${tempName}-${tempVersion}` });
        const { designContent, templateMetaData } = data;
        setTemplateMetaData(JSON.parse(templateMetaData));
        return JSON.parse(designContent);
    };

    const getTemplateVersionsFromSdk = async (tempName) => {
        const { data: { templateDataSet } } = await window.sdk.versionListClientidTemplatenameGet({ clientId, templatename: tempName });
        return templateDataSet;
    };

    const getPreviewFromSdk = async (tempName, tempVersion) => {
        const { data } = await window.sdk.templatePreviewClientidTemplatenameGet({ clientId, templatename: `${tempName}-${tempVersion}` });
        return data;
    };

    const sendTestEmailFromSdk = async (tempName, tempVersion) => {
        const body = {
            ambientData: JSON.stringify([...propList]),
            deliveryAddress: toEmail
        };

        const { data } = await window.sdk.templateTestSendClientidTemplatenamePost({ clientId, templatename: `${tempName}-${tempVersion}` }, body);
        return data;
    };

    const allTemplateStatus = async () => {
        const { data: { templateDataSet: allTemplatesDataList } } = await window.sdk.templateStatusListClientidStatusGet({ clientid: clientId, status: 'ALL' });
        setDraftTemplates(allTemplatesDataList.filter(template => template.templateStatus === 'DRAFT'));
        setReviewTemplates(allTemplatesDataList.filter(template => template.templateStatus === 'REVIEW'));
        setApprovedTemplates(allTemplatesDataList.filter(template => template.templateStatus === 'APPROVED'));
        setActiveTemplates(allTemplatesDataList.filter(template => template.templateStatus === 'ACTIVE'));
        setVersionList(allTemplatesDataList.filter(template => template.templateName === tempName));
    };

    const formatAssetSelection = async (assetName) => {

        const assetSelectorArray = [];

        const { data: { assetDataSet: allAssetData } } = await window.sdk.assetStatusListClientidStatusGet({ clientId, status: 'ALL' });

        setReviewAssets(allAssetData.filter(asset => asset.assetStatus === 'REVIEW'));

        allAssetData.forEach((node) => {
            if (assetName === node.assetName) {
                assetSelectorArray.push({ assetName: node.assetName, assetVersion: node.assetVersion });

                if (parseInt(node.assetVersion) > maxAssetVersion) {
                    maxAssetVersion = parseInt(node.assetVersion);
                }
            }
        });

        return assetSelectorArray;

    };

    const generateBeeHtml = async (pageJson) => {
        let parsedHtml = '';
        let authToken = refreshToken.current?.access_token !== undefined ? refreshToken.current.access_token : token.access_token ; //Pulling access token from current Bee session OR using refresh token
        try {
            parsedHtml = await updateHTML(pageJson.page, authToken); //Retrieving correctly parsed HTML from Bee
        } catch(e) {
            //If we receive a 401 from Bee we'll trigger an auth refresh and retrieve the parsed HTML with a refreshed auth token
            if (e.status === 401) { 
                const refreshResponse = await refreshBeeAuth(authToken);
                authToken = refreshResponse.data.access_token;
                refreshToken.current = refreshResponse.data;
                parsedHtml = await updateHTML(pageJson.page, authToken); //Retrying HTML parser after Bee auth refresh
            }
        }

        return parsedHtml;
    };

    const openNavigateAwayDialog = () => {
        dialog.setTitle(LOCALE.NAVIGATE_AWAY.TITLE);
        dialog.setText(LOCALE.NAVIGATE_AWAY.TEXT);
        dialog.setCls('');
        dialog.setIcon('');
        dialog.setNoText(LOCALE.GLOBAL.NO);
        dialog.setYesText(LOCALE.GLOBAL.YES);
        dialog.setYesCallback(() => {

        });
        dialog.setRenderComponentsMethod(() => {
            //Passing empty function to clear any previously rendered components
        });
        dialog.open();
    };

    const openSaveErrorDialog = (message) => {
        dialog.setTitle(dialogTextInput ? LOCALE.ASSET.CREATE.ERROR_TITLE : LOCALE.TEMPLATE.CREATE.ON_SAVE_ERROR);
        dialog.setIcon(warningIcon());
        dialog.setCls('warning');
        dialog.setText(message);
        dialog.setNoText('');
        dialog.setYesText(LOCALE.GLOBAL.OK);
        dialog.setYesCallback(() => {

        });
        dialog.setRenderComponentsMethod(() => {
            //Passing empty function to clear any previously rendered components
        });
        dialog.open();
    };

    const createTemplate = async (tempJson, tempHtml, templateMetaData) => {
        if (window.templateName) {
            status.open();
            const body = {
                templateName: window.templateName,
                author,
                designContent: tempJson,
                htmlContent: tempHtml,
                comments: '',
                templateMetaData
            }

            try {
                await window.sdk.templateCreateClientidPost({ clientId }, body);
                goToEdit(navigate, location, window.templateName, 'DRAFT'); //Redirecting to the newly created template after creation
            } catch (e) {
                status.close();
                openSaveErrorDialog(formatErrorMessage(e));
            }
        }
    };

    const saveAssetVersion = async (pageJson, rowJson) => {
        if (assetName) {
            status.open();
            const body = {
                author,
                assetName,
                assetNameVersion: `${assetName}-${maxAssetVersion}`,
                pageJson: pageJson,
                rowJson: rowJson,
                comments: '',
                tags: ''
            }

            try {
                await window.sdk.assetUpdateClientidTemplatenamePut({ clientId, templatename: assetName }, body);
                status.close();
            } catch (e) {
                //Incrementing asset version to redirect to a newly created asset after saving, should always redirect to (n + 1) where n is the previous max asset version
                if (e.status === 0) {
                    maxAssetVersion++;
                    goToAssetEdit(navigate, location, assetName, maxAssetVersion);
                } else {
                    status.close();
                }
            }
        }
    };

    const saveTemplateVersion = async (tempJson, tempHtml, templateMetaData) => {
        if (templateName) {
            status.open();
            const body = {
                author,
                templateName,
                templateNameVersion: `${templateName}-${templateVersion}`,
                designContent: tempJson,
                htmlContent: tempHtml,
                comments: '',
                templateMetaData
            }

            try {
                const { data } = await window.sdk.templateUpdateClientidTemplatenamePut({ clientId, templatename: templateName }, body);
                if (data.templateVersion !== templateVersion) {
                    goToEdit(navigate, location, data.templateName, data.templateVersion);
                }
                status.close();
            } catch (e) {
                status.close();
                openSaveErrorDialog(formatErrorMessage(e));
            }
        }
    };

    //Parsing error message and populating dialog with error message/reason
    const formatErrorMessage = (errorMessage) => {
        if (errorMessage.data.message && errorMessage.status === 400) {
            try {
                const parsedErrMsg = JSON.parse(String(errorMessage.data.message));

                // If the error line and index are not provided, context and caret won't be present
                if (parsedErrMsg.errorContext && parsedErrMsg.errorCaret) {

                    return (<>
                        {parsedErrMsg.description}<br />
                        Error: <br />{parsedErrMsg.error}<br /><br />
                        Context: <br /><text style={{ whiteSpace: "pre-wrap", fontFamily: "Courier" }}>{parsedErrMsg.errorContext}<br></br>{parsedErrMsg.errorCaret}</text>
                    </>)

                } else {

                    return (<>
                        {parsedErrMsg.description}<br />
                        Error: {parsedErrMsg.error}<br /><br />
                    </>)

                }
            } catch (error) {
                // message not JSON object, so just return it
                return errorMessage.data.message;
            }
        } else if (errorMessage.status === 0){
            return LOCALE.ASSET.CREATE.ERROR_TEXT;
        } else {
            return LOCALE.TEMPLATE.CREATE.ERROR;
        }
    };

    const getAppToken = async () => {
        try {
            const { data } = await window.sdk.beeAuthGet();
            const editor = new Bee(data);
            setEditor(editor);
            setToken(data);
        } catch (e) {
            goToLoginInit();
        }
    };

    const buildMetaData = () => {
        return JSON.stringify({
            "subject": subjectField,
            "fromEmailLocalPart": fromField,
            "replyTo": replyToField,
            "fromName": fromNameField,
            "ambientData": [...propList]
        });
    };

    const saveTemplate = () => {
        const templateMetaData = buildMetaData();
        editor.tempMetaData = templateMetaData;
        editor.save();
    };


    const init = async () => {
        status.open();

        const params = {
            token: token,
            config: {
                uid: clientId,
                container: 'bee-plugin-container',
                trackChanges: true,
                commenting: true,
                mergeTags: mergeTagsJson,
                username: author,
                userColor: '#5C3883',  //We can reassign this to be client specific if needed
                userHandle: author,
                autosave: roles !== ROLES.VIEWER ? 120 : false,
                workspace: {
                    editSingleRow: (assetName) ? true : false
                },
                onSave: (tempJson, tempHtml) => {
                    if (assetName) {
                        const { page } = JSON.parse(tempJson);
                        const rowJson = JSON.stringify(page.rows[0]);
                        setHasChanged(false); //After a manual save, reset hasChanged to false to disable the save button until a new change has occurred
                        saveAssetVersion(tempJson, rowJson);
                    }
                    else if (isEditing) {
                        setHasChanged(false); //After a manual save, reset hasChanged to false to disable the save button until a new change has occurred
                        allowAutoSave.current = false; //Reset allowAutoSave to false after a manual save so an autosave won't fire after a manual save
                        saveTemplateVersion(tempJson, tempHtml, editor.tempMetaData);
                    }
                    else {
                        createTemplate(tempJson, tempHtml, editor.tempMetaData);
                    }
                },
                onAutoSave: async (tempJson) => {  
                    if (isEditing && templateVersion === 'DRAFT' && allowAutoSave.current) {
                        allowAutoSave.current = false;
                        setHasChanged(false);  
                        const parsedTempJson = JSON.parse(tempJson); 
                        const beeHtml = await generateBeeHtml(parsedTempJson);  
                        setAutoSaveTime(moment(new Date()).format('hh:mm A'));
                        saveTemplateVersion(tempJson, beeHtml.data, loadedTempMetaData.current);
                        status.close();
                    } 
                },
                onError: (e) => {

                },
                onWarning: (e) => {

                },
                saveRows: (roles === ROLES.ADMIN || roles === ROLES.EDITOR) ? true : false,
                hooks: {
                    getRows: {
                        handler: async (resolve, reject, args) => {

                            const response = await window.sdk.assetListClientidGet({ clientId });

                            const assets = response.data.assetsMetaData;

                            const rowJsonAttributes = [];

                            // Loop through the assets and collect the rowJson attributes
                            for (const asset of Object.values(assets)) {
                                for (const assetData of asset) {
                                    if (assetData.hasOwnProperty('rowJson')) {
                                        rowJsonAttributes.push(JSON.parse(assetData.rowJson));
                                    }
                                }
                            }
                            resolve(rowJsonAttributes);
                        }
                    }
                },
                rowsConfiguration: {
                    defaultRows: true,
                    emptyRows: true,
                    externalContentURLs: [
                        {
                            name: LOCALE.CONTENT.SHARED_ASSETS,
                            value: "category-value",
                            handle: "category-handle",
                            isLocal: true,
                        }]
                },
                onChange: (tempJson) => {
                    allowAutoSave.current = true;
                    setHasChanged(true);
                    trackJsonChanges.current = tempJson;
                },
                onLoad: (tempJson) => {
                    if (isReadOnly) {
                        toggleBeePreview();
                        allTemplateStatus();
                    }

                    trackJsonChanges.current = JSON.stringify({
                        "page": tempJson
                    });
                    
                },
                onSaveRow: async (rowJSON, rowHTML, pageJSON) => {
                    status.open();

                    //Parsing rowJSON to update JSON 'Synced' attribute
                    const tempRowJSON = JSON.parse(rowJSON);

                    //Setting JSON 'Synced' attribute to true
                    tempRowJSON.synced = true;

                    //Reassigning rowJSON to the updated JSON with 'Synced' set to true
                    rowJSON = JSON.stringify(tempRowJSON);

                    const tempPageJSON = JSON.parse(pageJSON);

                    //Reassigning top-level 'Synced' attribute to true
                    tempPageJSON.synced = true;

                    //Reassigning child JSON 'Synced' attribute to true
                    tempPageJSON.page.rows[0].synced = true;

                    pageJSON = JSON.stringify(tempPageJSON);


                    const savedRow = {
                        assetName:  dialogTextInput,
                        pageJson: pageJSON,
                        rowJson: rowJSON,
                        comments: '',
                        tags: '',
                        author,
                        templatesUsingAsset: 'NA',
                    }

                    try {
                        const res = await window.sdk.assetCreateClientidPost({ clientId }, savedRow);
                        status.close();
                    } catch (error) {
                        openSaveErrorDialog(formatErrorMessage(error));
                        status.close();
                    }
                },
                contentDialog: {
                    saveRow: {
                        label: LOCALE.ROW.SAVING,
                        handler: async (resolve, reject, args) => {
                            dialog.setCls('');
                            dialog.setIcon('');  
                            dialog.setUnsyncedText('');
                            dialog.setRenderComponentsMethod(renderAssetDialogComponents);
                            dialog.setTitle(LOCALE.ASSET.CREATE.TITLE);
                            dialog.setText(LOCALE.ASSET.CREATE.TEXT);
                            dialog.setNoText(LOCALE.GLOBAL.CANCEL);
                            dialog.setIsDisabled(true);
                            dialog.setNoCallback(() => {
                                reject();
                            });
                            dialog.setYesText(LOCALE.GLOBAL.CREATE);
                            dialog.setYesCallback(() => {
                                dialogTextInput.trim()?.length > 0 ? resolve({name: dialogTextInput}) : reject();
                            });
                            dialog.open();
                        },
                    }, editSyncedRow: {
                        label: LOCALE.SYNCED.BEE_LABEL,
                        description: LOCALE.SYNCED.BEE_DESCRIPTION,
                        notPermittedDescription: LOCALE.SYNCED.BEE_NOT_PERMITTED,
                        handler: async (resolve, reject, args) => {

                            try {
                                if (roles === ROLES.ADMIN || roles === ROLES.EDITOR) {

                                    //Custom dialog modal used to process interactive input.
                                    const openOptionsDialog = () => {
                                        dialog.setCls('');
                                        dialog.setIcon('');
                                        dialog.setTitle(LOCALE.SYNCED.TITLE);
                                        dialog.setText(LOCALE.SYNCED.TEXT);
                                        dialog.setNoText(LOCALE.SYNCED.CANCEL);
                                        dialog.setYesText(LOCALE.SYNCED.EDIT_ASSET);
                                        dialog.setUnsyncedText(LOCALE.SYNCED.UNSYNC_TEXT);
                                        dialog.setRenderComponentsMethod(() => {
                                            //Passing empty function to clear any previously rendered components
                                        });
                                        dialog.setNoCallback(() => {
                                            resolve(true);
                                        });
                                        dialog.setUnsyncedCallback(() => {
                                            resolve(false);
                                        });
                                        dialog.setYesCallback(async () => {
                                            const { data: { assetsMetaData } } = await window.sdk.assetListClientidGet({ clientId });
                                            const syncedAssetName = args.metadata.name;
                                            const syncedAssetVer = assetsMetaData[syncedAssetName][0].assetActiveVersion;
                                            goToAssetEdit(navigate, location, syncedAssetName, syncedAssetVer);
                                        });
                                        dialog.open();
                                    };

                                    //Opening dialog modal to process user input
                                    openOptionsDialog();

                                } else {
                                    reject();
                                }
                            } catch (error) {
                                console.log("error", error);
                            }

                        }
                    }
                },
                editorFonts: {
                    showDefaultFonts: false,
                    customFonts: customFontsJson //Loading in a custom JSON file with correct fall-back fonts for Maximus
                }
            }
        };

        let template, versions;
        try {
            if (assetName) {
                const assetData = await getAssetMetaDataFromSdk();
                versions = assetData.assetVersions;
                const pageJson = assetData.assetJSON;
                setRowJson(JSON.parse(pageJson));
                template = JSON.parse(pageJson);
                setVersionList(versions);
            } else if (tempName && templateVersion) {
                template = await getTemplateFromSdk(tempName, templateVersion);

                if (!isReadOnly) {
                    versions = await getTemplateVersionsFromSdk(tempName);
                    setVersionList(versions);
                }
            }
            else {
                template = await getTemplate();
                template = template.page;
            }

            startEditor(params, template, editor);
            status.close();
        } catch (e) {
            dialog.setTitle(assetName ? LOCALE.ASSET.LOAD.ERROR_TITLE : LOCALE.TEMPLATE.LOAD.ERROR_TITLE);
            dialog.setText(assetName ? LOCALE.ASSET.LOAD.ERROR : LOCALE.TEMPLATE.LOAD.ERROR);
            dialog.setNoText('');
            dialog.setYesText(LOCALE.GLOBAL.OK);
            dialog.open();
            status.close();
        }
    };

    const setTemplateName = ({ target: { value } }) => {
        if (!isAlphaWithUnderscore(value) && value.trim() < 1) {
            return false;
        }

        setTempName(value.split(' ').join(''));
        window.templateName = value.split(' ').join('');
    };

    const handleTemplateVersionChange = ({ target: { value } }) => {
        if (roles !== ROLES.VIEWER) {
            goToEdit(navigate, location, tempName, value);
        } else {
            goToEditReadOnly(navigate, location, tempName, value);
        }
    };

    const handleAssetVersionChange = ({ target: { value } }) => {
        if (roles !== ROLES.VIEWER) {
            goToAssetEdit(navigate, location, assetName, value);
        } else {
            goToAssetEditReadOnly(navigate, location, assetName, value);
        }
    };

    const handleClickSettings = () => {
        togglePropertiesDrawer();
    };

    const setPropKey = (value, idx) => {
        if (!isAlphaWithHyphenUnderscore(value)) {
            return false;
        }

        propList[idx]['key'] = value.toUpperCase();
        setPropList([
            ...propList
        ]);

        setHasChanged(true);
        allowAutoSave.current = true;
    };

    const setPropValue = (value, idx) => {
        propList[idx]['value'] = value;
        setPropList([
            ...propList
        ]);

        setHasChanged(true);
        allowAutoSave.current = true;
    };

    const addSettingsPropertyRow = () => {
        const tempJson = { ...blankJson };
        propList.push(tempJson);
        setPropList([
            ...propList
        ]);
        
        setHasChanged(true);
        allowAutoSave.current = true;
    };

    const removeSettingsPropertyRow = (idx) => {
        propList.splice(idx, 1);
        setPropList([
            ...propList
        ]);

        setHasChanged(true);
        allowAutoSave.current = true;
    };

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);

        if (newValue === 1) {
            if (jsonEditor) {
                jsonEditor.destroy();
            }

            const edit = new JSONEditor(editorContainer, {
                mode: 'code',
                onChangeText: onChangeJSON,
                navigationBar: false,
                mainMenuBar: false,
                search: false
            });
            edit.set(propList);
            setJsonEditor(edit);
        }
    };

    const isValidToPreview = () => {
        return (subjectField && fromNameField && fromField && replyToField);
    };

    const isValidToSave = () => {
        return hasChanged && (tempName || assetName); //Checking if any changes have occurred within a template/asset
    };

    const renderSettings = () => {
        return (
            <Grid container xs={12} spacing={2} style={{ width: '400px' }}>
                <Grid xs={12} className={'settings-header'}>
                    <Typography variant={'h5'} gutterBottom>{templateName}</Typography>
                </Grid>
                <Box
                    component="form"
                    autoComplete="off"
                >
                    <Grid xs={12}>
                        <TextField
                            error={subjectField ? false : true}
                            required
                            label={LOCALE.TEMPLATE.PROPERTIES.SUBJECT}
                            id="subject-field"
                            value={subjectField}
                            onChange={ev => {
                                setSubjectField(ev.target.value);
                                setHasChanged(true);
                                allowAutoSave.current = true;
                            }}
                            margin="none"
                            style={settingsTextStyles}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            error={fromNameField ? false : true}
                            required
                            label={LOCALE.TEMPLATE.PROPERTIES.FROM_NAME}
                            id="to-field"
                            value={fromNameField}
                            onChange={ev => {
                                const { value } = ev.target;
                                setFromNameField(value);
                                setHasChanged(true);
                                allowAutoSave.current = true;
                            }}
                            margin="none"
                            style={settingsTextStyles}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            error={fromField ? false : true}
                            required
                            label={LOCALE.TEMPLATE.PROPERTIES.FROM_EMAIL_LOCAL}
                            id="from-field"
                            value={fromField}
                            onChange={ev => {
                                const { value } = ev.target;
                                if (!isAlphaNumeric(value)) {
                                    return false;
                                }

                                setFromField(value);
                                setHasChanged(true);
                                allowAutoSave.current = true;
                            }}
                            margin="none"
                            style={settingsTextStyles}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            error={replyToField ? false : true}
                            required
                            label={LOCALE.TEMPLATE.PROPERTIES.REPLY_TO}
                            id="from-field"
                            value={replyToField}
                            onChange={ev => {
                                const { value } = ev.target;
                                if (!isValidEmail(value)) {
                                    return false;
                                }
                                
                                setReplyToField(value);
                                setHasChanged(true);
                                allowAutoSave.current = true;
                            }}
                            margin="none"
                            style={settingsTextStyles}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Grid container xs={12}>
                            <Grid xs={6}>
                                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                    <Tab label="Properties" id={'prop-tab'} aria-controls={'simple-prop-tabpanel'} />
                                    <Tab label="JSON" id={'json-tab'} aria-controls={'simple-json-tabpanel'} />
                                </Tabs>
                            </Grid>
                            <Grid xs={5} justifyContent={'right'} alignContent={'right'} display={'flex'}>
                                <AddIcon onClick={addSettingsPropertyRow} style={{ cursor: "pointer" }} />
                            </Grid>
                        </Grid>
                        <div role="tabpanel" hidden={tabValue !== 0} id={'simple-prop-tabpanel'} aria-labelledby={'prop-tab'}>
                            {propList.map((prop, idx) => {
                                return (<Grid container xs={12} key={`propRow_${idx}`}>
                                    <Grid xs={5}>
                                        <TextField
                                            label={LOCALE.TEMPLATE.SETTINGS.NAME}
                                            value={prop.key}
                                            onChange={ev => setPropKey(ev.target.value, idx)}
                                            style={{ ...settingsPropertiesTextStyles, textTransform: 'uppercase' }}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <TextField
                                            label={LOCALE.TEMPLATE.SETTINGS.VALUE}
                                            value={prop.value}
                                            onChange={ev => setPropValue(ev.target.value, idx)}
                                            style={{ ...settingsPropertiesTextStyles, textTransform: 'uppercase' }}
                                        />
                                    </Grid>
                                    <Grid xs={1}>
                                        <DeleteIcon style={{ cursor: "pointer" }} onClick={p => removeSettingsPropertyRow(idx)} />
                                    </Grid>
                                </Grid>);
                            })}
                        </div>
                        <div role="tabpanel" hidden={tabValue !== 1} id={'simple-json-tabpanel'} aria-labelledby={'json-tab'}>
                            <div className="jsoneditor-react-container" ref={elem => setEditorContainer(elem)} />
                        </div>
                    </Grid>
                </Box>
            </Grid>
        );
    };

    const settingsTextStyles = {
        marginRight: 10,
        marginLeft: 10,
        width: '350px'
    };

    const settingsPropertiesTextStyles = {
        marginRight: 10,
        marginLeft: 10,
        width: '150px'
    };

    const showSendTest = () => {
        setShowTestSend(!showTestSend);
    };

    //Navigate back to intial route.
    const handleBack = () => {
        const prevPathName = location?.state?.prevPath?.pathname;
        navigate(prevPathName);
    };

    const exitTestSend = () => {
        setShowPreviewHtml(false);
        setShowPreview(false);
        setShowTestSend(false);
    };

    const sendTest = async () => {
        if (toEmail) {
            status.open();
            await sendTestEmailFromSdk(tempName, templateVersion);
            status.close();
            showSendTest();
            togglePreview();
        }
    };

    const toggleBeePreview = async () => {
        editor.togglePreview();
        setIsBeePreview(!isBeePreview);
    }

    const togglePreview = async () => {
        setShowPreview(!showPreview);
        if (!showPreview) {
            try {
                if (allowAutoSave.current) {
                    status.open();
                    const tempMetaData = buildMetaData();
                    const beeHtml = await generateBeeHtml(JSON.parse(trackJsonChanges.current));
                    await saveTemplateVersion(trackJsonChanges.current, beeHtml.data, tempMetaData);
                    setHasChanged(false); //Resetting hasChanged state to false after firing a save event
                    allowAutoSave.current = false; //Disabling the ability to autosave as all changes have been captured by the save event
                    status.close();
                }
                status.open();
                const data = await getPreviewFromSdk(tempName, templateVersion);
                setRawHtml(data);
                showSendTest();
                status.close();
            } catch (e) {
                const { data: { message } } = e;
                dialog.setTitle(LOCALE.TEMPLATE.PREVIEW.ERROR_TITLE);
                dialog.setIcon(warningIcon());
                dialog.setCls('warning');
                dialog.setText(message);
                dialog.setNoText('');
                dialog.setYesText(LOCALE.TEMPLATE.PREVIEW.EXIT_PREVIEW);
                dialog.setYesCallback(() => {
                    exitTestSend();
                });
                dialog.open();

                status.close();
            }
        }
    };

    const togglePreviewHTML = () => {
        setShowPreviewHtml(!showPreviewHtml);
    };

    const onChangeJSON = (json) => {
        if (isValidJSON(json)) {
            const formattedJson = formatJson(JSON.parse(json));
            setPropList(formattedJson);
        }
    };

    const formatJson = (json) => {
        const fJson = json.map(o => {
            return {
                key: o.key.toUpperCase().split(" ").join(""),
                value: o.value
            };
        });
        return fJson;
    }

    const togglePropertiesDrawer = () => {
        setIsPropertyOpen(!isPropertyOpen);
    };

    const renderTemplatesAffected = () => {
        if (templateAffectedList?.length > 1) {
            return templateAffectedList.map(t => <div>{t.templateName}</div>);
        } else {
            return LOCALE.ASSET.VIEW_TEMPLATES_EMPTY;
        }
    };

    const showTemplatesAffected = () => {
        dialog.setTitle(LOCALE.TEMPLATE.AFFECTED_TITLE);
        dialog.setText(LOCALE.TEMPLATE.AFFECTED);
        dialog.setNoText('');
        dialog.setCls('');
        dialog.setIcon('');
        dialog.setYesText(LOCALE.GLOBAL.OK);
        dialog.setRenderComponentsMethod(renderTemplatesAffected)
        dialog.open();

        status.close();
    };

    const approveTemplateChanges = async () => {

        const affectedTemplates = [];

        templateAffectedList.forEach(t => {
            if (t.templateName) {
                affectedTemplates.push(t.templateName);
            }
        });

        const body = {
            clientId,
            assetName,
            assetVersion,
            rowJson: JSON.stringify(rowJson),
            sessionApiKey: sessionId,
            templatesToUpdate: affectedTemplates
        };

        status.open();
        await window.sdk.assetApproveClientidPost({ clientId }, body);
        status.close();

        dialog.setTitle(LOCALE.SHARED.APPROVED_TITLE);
        dialog.setText(LOCALE.SHARED.APPROVED);
        dialog.setNoText('');
        dialog.setYesText(LOCALE.GLOBAL.OK);
        dialog.setYesCallback(() => {
            goToShared();
        });
        dialog.open();
    };

    //SaveAs Feature implementation
    const fetchTempDesignJson = async () => {
        const data = await getTemplateFromSdk(tempName, templateVersion);
        return JSON.stringify(data);
    };

    const SaveAsTemplateFeature = async (tempName, tempJson, tempHtml, tMetaData) => {
        status.open();
        const body = {
            templateName: tempName,
            author,
            designContent: tempJson,
            htmlContent: tempHtml,
            comments: '',
            templateMetaData: tMetaData
        }

        try {
            await window.sdk.templateCreateClientidPost({ clientId }, body);
            goToEdit(navigate, location, tempName, 'DRAFT'); //Redirecting to newly created template 
        } catch (e) {
            status.close();
            openSaveErrorDialog(formatErrorMessage(e));

        }

    };

    const renderDialogcomponents = () => {
        return <TextField
            id="item-name"
            label="Template Name"
            onKeyDown={e => !isAlphaWithUnderscore(e.key) ? e.preventDefault() : e}
            onChange={e => {validateUserInput(e); setItemName(e.target.value)}}
        />;
    };

    const renderUserComments = () => {
        return <TextField
        helperText={ isCommentRequired.current ? LOCALE.PROMOTION.COMMENT_REQUIRED_TEXT : LOCALE.PROMOTION.COMMENT_OPTIONAL_TEXT}
        multiline={true}
        autoFocus={true}
        fullWidth={true}
        id="workflow-comments"
        label={LOCALE.PROMOTION.COMMENT_LABEL}
        onChange={e => isCommentRequired.current ? validateUserInput(e) : dialogTextInput = e.target.value}
    />;
    };

    const renderAssetDialogComponents = () => {
        return <TextField
            id="asset-name"
            label="Asset Name"
            onKeyDown={e => !isAlphaWithUnderscore(e.key) ? e.preventDefault() : e}
            onChange={e => validateUserInput(e)}
        />;
    };

    const validateUserInput = (e) => {

        if ( !e.target.value.trim().length > 0 ) {
            dialog.setIsDisabled(true);
        } else {
            dialog.setIsDisabled(false);
            dialogTextInput = e.target.value;
        }
    };

    const copyToFeature = async () => {

        dialog.setTitle(LOCALE.TEMPLATE.SAVEAS.TITLE);
        dialog.setText(LOCALE.TEMPLATE.SAVEAS.TEXT);
        dialog.setNoText(LOCALE.TEMPLATE.SAVEAS.NOTEXT);
        dialog.setYesText(LOCALE.TEMPLATE.SAVEAS.YESTEXT);
        dialog.setCls('');
        dialog.setIcon('');
        dialog.setIsDisabled(true);
        dialog.setUnsyncedText(() => {})
        dialog.setRenderComponentsMethod(renderDialogcomponents);
        dialog.open();

    };

    const downloadHtml = () => {
        const file = new File([prettier.format(rawHtml, {
            parser: "html",
            plugins: [parserHtml]
        })], `${templateName}.html`, {
            type: 'text/plain',
        });

        const link = document.createElement('a');
        const url = URL.createObjectURL(file);

        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const renderApproveText = (templateName, templateVersion) => {
        return (
            <>
                {LOCALE.PROMOTION.APPROVE_TEXT}&nbsp;
                <span className={'delete-modal-template-name'}>{templateName}</span>&nbsp;
                {LOCALE.PROMOTION.VERSION}:&nbsp;
                <span className={'delete-modal-template-name'}>{templateVersion}</span>?
                <div style={{'paddingTop': '20px'}}>{ renderUserComments() }</div>
            </>
        );
    };

    const renderRejectText = (templateName, templateVersion) => {
        return (
            <>
                {LOCALE.PROMOTION.REJECT_TEXT}&nbsp;
                <span className={'delete-modal-template-name'}>{templateName}</span>&nbsp;
                {LOCALE.PROMOTION.VERSION}:&nbsp;
                <span className={'delete-modal-template-name'}>{templateVersion}</span>?
                <div style={{'paddingTop': '20px'}}>{ renderUserComments() }</div>
            </>
        );
    };

    const renderRejectOverwriteText = (templateName, templateVersion) => {
        return (
            <>
                {LOCALE.PROMOTION.REJECT_TEXT}&nbsp;
                <span className={'delete-modal-template-name'}>{templateName}</span>&nbsp;
                {LOCALE.PROMOTION.VERSION}:&nbsp;
                <span className={'delete-modal-template-name'}>{templateVersion}</span>?<br/>
                <span className={'delete-modal-template-name'}>{LOCALE.PROMOTION.REJECT_OVERWRITE_TEXT}</span>
                <div style={{'paddingTop': '20px'}}>{ renderUserComments() }</div>
            </>
        );
    };

    const renderDisapproveText = (templateName, templateVersion) => {
        return (
            <>
                {LOCALE.PROMOTION.DISAPPROVE_TEXT}&nbsp;
                <span className={'delete-modal-template-name'}>{templateName}</span>&nbsp;
                {LOCALE.PROMOTION.VERSION}:&nbsp;
                <span className={'delete-modal-template-name'}>{templateVersion}</span>?
                <div style={{'paddingTop': '20px'}}>{ renderUserComments() }</div>
            </>
        );
    };

    const renderDisapproveOverwriteText = (templateName, templateVersion) => {
        return (
            <>
                {LOCALE.PROMOTION.DISAPPROVE_TEXT}&nbsp;
                <span className={'delete-modal-template-name'}>{templateName}</span>&nbsp;
                {LOCALE.PROMOTION.VERSION}:&nbsp;
                <span className={'delete-modal-template-name'}>{templateVersion}</span>?<br/>
                <span className={'delete-modal-template-name'}>{LOCALE.PROMOTION.DISAPPROVE_OVERWRITE_TEXT}</span>
                <div style={{'paddingTop': '20px'}}>{ renderUserComments() }</div>
            </>
        );
    };

    const renderDeactivateText = (templateName, templateVersion) => {
        return (
            <>
                {LOCALE.PROMOTION.DEACTIVATE_TEXT}&nbsp;
                <span className={'delete-modal-template-name'}>{templateName}</span>&nbsp;
                {LOCALE.PROMOTION.VERSION}:&nbsp;
                <span className={'delete-modal-template-name'}>{templateVersion}</span>?
                <div style={{'paddingTop': '20px'}}>{ renderUserComments() }</div>
            </>
        );
    };

    const handleClickApprove = () => {
        isCommentRequired.current = false;
        const type = templateName ? 'template' : 'asset';
        const name = templateName ? templateName : assetName;
        const version = templateVersion ? templateVersion : assetVersion;

        dialog.setTitle(`Approve ${type}?`);
        dialog.setText('');
        dialog.setYesText(LOCALE.GLOBAL.YES);
        dialog.setNoText(LOCALE.GLOBAL.NO);
        dialog.setYesCallback(() => {
            promoteRow(name, version, "APPROVE", dialogTextInput);
        });
        dialog.setRenderComponentsMethod(() => renderApproveText(name, version));
        dialog.open();
    };

    const handleClickDeactivate = (templatename, templateVersion, type) => {
        isCommentRequired.current = true;
        dialog.setTitle(`${LOCALE.TEMPLATE.GRID_DEACTIVATE} ${type}?`);
        dialog.setText('');
        dialog.setYesText(LOCALE.GLOBAL.YES);
        dialog.setNoText(LOCALE.GLOBAL.NO);
        dialog.setIsDisabled(true);
        dialog.setYesCallback(() => {
            promoteRow(templatename, templateVersion, "DEACTIVATE", dialogTextInput);
        });
        dialog.setRenderComponentsMethod(() => renderDeactivateText(templatename, templateVersion));
        dialog.open();
    };

    const handleClickDisapprove = (templatename, templateVersion, type) => {
        isCommentRequired.current = true;
        const hasDraft = draftTemplates.some(template => template.templateName === `${templateName}`);
        
        dialog.setTitle(`${LOCALE.TEMPLATE.GRID_DISAPPROVE} ${type}?`);
        dialog.setText('');
        dialog.setYesText(LOCALE.GLOBAL.YES);
        dialog.setNoText(LOCALE.GLOBAL.NO);
        dialog.setIsDisabled(true);
        dialog.setYesCallback(() => {
            promoteRow(templatename, templateVersion, "REJECT", dialogTextInput);
        });
        dialog.setRenderComponentsMethod(() => hasDraft ? renderDisapproveOverwriteText(templateName, templateVersion) : renderDisapproveText(templatename, templateVersion));
        dialog.open();
    };

    const handleClickReject = () => {
        isCommentRequired.current = true;
        const type = templateName ? 'template' : 'asset';
        const name = templateName ? templateName : assetName;
        const version = templateVersion ? templateVersion : assetVersion;
        const hasDraft = draftTemplates.some(template => template.templateName === `${name}`);

        dialog.setTitle(`${LOCALE.TEMPLATE.GRID_REJECT} ${type}?`);
        dialog.setText('');
        dialog.setYesText(LOCALE.GLOBAL.YES);
        dialog.setNoText(LOCALE.GLOBAL.NO);
        dialog.setIsDisabled(true);
        dialog.setYesCallback(() => {
            promoteRow(name, version, "REJECT", dialogTextInput);
        });
        dialog.setRenderComponentsMethod(() => hasDraft ? renderRejectOverwriteText(name, version) : renderRejectText(name, version));
        dialog.open();
    };

    const promoteRow = async (templatename, templateVersion, templateStatus, comments) => {
        const body = {};
        const type = templateName ? 'template' : 'asset';

        try {
            status.open();
            if (type === 'template') {
                body.promotion = templateStatus;
                body.comments = comments;
                body[`${type}NameVersion`] = `${templatename}-${templateVersion}`;
                await window.sdk[`${type}PromotionClientidTemplatenamePost`]({ templatename, clientid: clientId }, body);
            } else {
                body.promotion = templateStatus;
                body.templatesToUpdate = [];
                body.assetName = templatename;
                body.assetVersion = templateVersion;
                body.comments = comments;
                await window.sdk[`${type}ApproveClientidPost`]({ templatename, clientId }, body);
            }

            if (type === "template") {
                goToTemplates();
            } else {
                goToShared();
            }
        }
        catch (e) {

        }
    };

    return (
        <>
            <Drawer anchor={'right'} open={isPropertyOpen}>
                <Box justifyContent={'right'} alignContent={'right'} display={'flex'}>
                    <CloseIcon onClick={togglePropertiesDrawer} />
                </Box>
                {renderSettings()}
            </Drawer>

            <Grid spacing={2} container xs={12} className={'template-name-container'}>
                <Grid xs={12} md={12} lg={4} className={'template-name'}>
                    {assetName ? <TextField
                        label={LOCALE.ASSET.NAME}
                        id="asset-name"
                        value={assetName}
                        disabled={!!isEditing}
                        className={'temp-name-fld'}
                    /> : <TextField
                        label={LOCALE.TEMPLATE.NAME}
                        id="template-name"
                        value={tempName}
                        disabled={!!isEditing}
                        onKeyDown={e => !isAlphaWithUnderscore(e.key) ? e.preventDefault() : e}
                        onChange={setTemplateName}
                        error={tempName ? false : true}
                        className={'temp-name-fld'}
                    />}
                </Grid>
                <Grid xs={12} md={12} lg={3}>
                    <FormControl fullWidth>
                        {versionList?.length && <InputLabel id="demo-simple-select-label"> {LOCALE.SHARED.VERSION}</InputLabel>}
                        {versionList?.length && <Select
                            id="version-select"
                            value={templateVersion ? templateVersion : assetVersion}
                            label={LOCALE.SHARED.VERSION}
                            onChange={templateVersion ? handleTemplateVersionChange : handleAssetVersionChange}
                            className={'temp-name-fld'}
                        >
                            {templateVersion ? versionList && versionList.sort((a, b) =>(Number.isNaN(parseInt(a.templateVersion))) || (parseInt(a.templateVersion) < parseInt(b.templateVersion)) ? 0 : -1).map(v =>
                                <MenuItem key={v.templateVersion} value={v.templateVersion}>{v.templateVersion === 'DRAFT' ? 'DRAFT' : `v${v.templateVersion}`}</MenuItem>
                            ) : versionList && versionList.sort((a, b) => (parseInt(a.assetVersion) < parseInt(b.assetVersion)) ? 0 : -1).map(v =>
                                <MenuItem key={v.assetVersion} value={v.assetVersion}>v{v.assetVersion}</MenuItem>
                            )}

                        </Select>}
                    </FormControl>
                </Grid>
                {roles !== ROLES.VIEWER && <Grid xs={12} md={12} lg={5} justifyContent={'right'} alignContent={'right'} display={'flex'}>
                    {
                    templateName && templateVersion && autoSaveTime &&
                    <Typography sx={{paddingTop : 2, paddingRight: 14, fontWeight: 'bold'}}>
                      {LOCALE.TEMPLATE.AUTOSAVE_TEXT}{autoSaveTime}
                    </Typography>
                    
                    }
                    {location.state != null && location?.pathname !== "/create" && <Button onClick={handleBack}>back</Button>}
                    {showPreview && <FormControlLabel control={<Switch onChange={togglePreviewHTML} />} label={LOCALE.TEMPLATE.PREVIEW.SHOW_HTML} />}

                    {showPreview && <Button onClick={exitTestSend}>{LOCALE.TEMPLATE.PREVIEW.EXIT_TEST_SEND}</Button>}

                    {
                        !isReadOnly && templateName &&
                        templateVersion &&
                        !showPreview &&
                        <Button onClick={toggleBeePreview} disabled={!isValidToPreview()}>
                            {!isBeePreview ? LOCALE.TEMPLATE.SHOW_PREVIEW : LOCALE.TEMPLATE.PREVIEW.EXIT_PREVIEW}
                        </Button>
                    }

                    {
                        !isReadOnly && templateName &&
                        templateVersion &&
                        !showPreview && <Button onClick={togglePreview}>{LOCALE.TEMPLATE.SEND_TEST_EMAIL}</Button>
                    }

                    {
                        !isReadOnly && !showPreview &&
                        <Button onClick={saveTemplate} disabled={!isValidToSave()}>{LOCALE.TEMPLATE.SAVE}</Button>
                    }
            
                    {
                        !isReadOnly && templateName &&
                        templateVersion &&
                        <Button onClick={copyToFeature}>{LOCALE.TEMPLATE.SAVEAS.BUTTONTEXT}</Button>
                    }
                    {!isReadOnly && !assetName && <SettingsIcon className={'settings-icon'} onClick={() => handleClickSettings()} />}
                </Grid >}
            </Grid >

            {(reviewTemplates.some(template => template.templateNameVersion === `${templateName}-${templateVersion}`) || reviewAssets.some(asset => asset.assetNameVersion === `${assetName}-${assetVersion}`))
                && roles !== ROLES.VIEWER && <Grid container xs={12} className={'template-name-container'}>
                    
                    {assetName && <Button variant={'contained'} className='grid-button' disabled={templateAffectedList?.length <= 1} onClick={showTemplatesAffected}>
                        <ListIcon className={'icon'} />
                        {LOCALE.ASSET.VIEW_TEMPLATES}
                    </Button>}

                    {roles === ROLES.ADMIN && <Button variant={'contained'} className='grid-button' onClick={() => handleClickApprove()}>
                        <ThumbUpIcon className={'icon'} />
                        {LOCALE.TEMPLATE.GRID_APPROVE}
                    </Button>}

                    <Button variant={'contained'} className='grid-button' onClick={() => handleClickReject()}>
                        <ThumbDownIcon className={'icon'} />
                        {LOCALE.TEMPLATE.GRID_REJECT}
                    </Button>
                </Grid>}

            {
                (approvedTemplates).some(template => template.templateNameVersion === `${templateName}-${templateVersion}`) && roles === ROLES.ADMIN && <Grid container xs={12} className={'template-name-container'}>

                    <Button variant={'contained'} className='grid-button' onClick={() => handleClickDisapprove(templateName, templateVersion, 'template')}>
                        <ThumbDownIcon className={'icon'} />
                        {LOCALE.TEMPLATE.GRID_DISAPPROVE}
                    </Button>

                </Grid>
            }

            {
                (activeTemplates.some(template => template.templateNameVersion === `${templateName}-${templateVersion}` )) && roles === ROLES.ADMIN && <Grid container xs={12} className={'template-name-container'}>
                    <Button variant={'contained'} className='grid-button' onClick={() => handleClickDeactivate(templateName, templateVersion, 'template')}>
                        <BlockIcon className={'icon'} />
                        {LOCALE.TEMPLATE.GRID_DEACTIVATE}
                    </Button>
                </Grid>
            }

            {
                showTestSend &&
                <Grid container xs={12} className={'test-email-name-container'}>
                    <Grid xs={12} justifyContent={'right'} alignContent={'right'} display={'flex'}>
                        <TextField
                            label={LOCALE.TEMPLATE.RECIPIENT_EMAIL}
                            id="recipient-email"
                            value={toEmail}
                            onChange={(e) => setToEmail(e.target.value)}
                            className={'temp-name-fld'}
                        />
                        <Button onClick={sendTest}>Send</Button>
                        <Button onClick={exitTestSend}>Cancel</Button>
                    </Grid>
                </Grid>
            }

            {
                showPreview && <div className='preview-code-container'>
                    {!showPreviewHtml
                        ? <div dangerouslySetInnerHTML={{ __html: rawHtml }} />
                        : <>
                            <div className='code-toolbar'>
                                <DownloadIcon onClick={downloadHtml} />
                            </div>
                            <div style={{ width: '100%', height: '100%' }}>
                                <CodeMirror
                                    value={prettier.format(rawHtml, {
                                        parser: "html",
                                        plugins: [parserHtml]
                                    })}
                                    height="100%"
                                    extensions={[html()]}
                                />
                            </div></>}
                </div>
            }
            <div id="bee-plugin-container" style={{ display: showPreview ? 'none' : 'block' }}></div>
        </>
    );
}

export default Create;