import WarningIcon from '@mui/icons-material/Warning';
import { USER_TYPE } from './roles';

export const isValidJSON = (jsonString) => {
    try {
        JSON.parse(jsonString);
    } catch (e) {
        return false;
    }
    return true;
};

export const isAlpha = (value) => {
    return /^[A-Za-z_]*$/.test(value);
}

export const isAlphaNumeric = (value) => {
    return /^[A-Za-z0-9]*$/.test(value);
}

export const isAlphaWithSpace = (value) => {
    return /^[A-Za-z0-9\s]*$/.test(value);
}

export const isAlphaWithHyphenUnderscore = (value) => {
    return /^[a-zA-Z0-9-_]*$/.test(value);
}

export const isAlphaWithUnderscore = (value) => {
    return /^[a-zA-Z0-9_]*$/.test(value);
}

export const isValidEmail = (value) => {
    return /^[a-zA-Z0-9-_.@]*$/.test(value);
}

export const getClientId = (value) => {
     // Always default to 100, this is ONLY used in local development where cookies aren't set.
    if (!value || value === "0") {
        return 100;
    } else if (value.includes(',')) {
        return value.split(',')[0];
    } else {
        return value;
    }
}

export const getMultiClientId = (value) => {
    if (value) {
        if (value.includes(',')) {
            return value.split(',');
        }
    }
}

export const getUserRoles = (value) => {
    if (value) {
        return value.split(',')[0];
    }
}

export const getUserType = (value) => {
    if (value) {
        if (value.includes('csg-azuread_')) {
            return USER_TYPE.MANAGED;
        } else {
            return USER_TYPE.NON_MANAGED;
        }
    }
}

export const roleFilter = (routes, role) => {
    return routes.filter(r => !r.roles || (r.roles.includes(role)));
}

export const getUserName = (value) => {
    if (value) {
        if (value.includes('csg-azuread_')) {
            return value.split('_')[1];
        } else {
            return value;
        }
    }
}

export const warningIcon = () => {
    return (
        <WarningIcon />
    );
}

export const getSessionData = (name) => {
    return JSON.parse(sessionStorage.getItem(name));
}

export const setSessionData = (name, data) => {
    sessionStorage.setItem(name, JSON.stringify(data));
}