import React from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import './index.css';

export const Picker = ({ items = [], value, action, label }) => {
    return (
        <TextField
            value={value}
            onChange={action}
            label={label}
            select
            className={'select-box'}
            >
            {items.map(item => (
                <MenuItem key={item.id} value={item.id}>
                    {item.name}
                </MenuItem>
            ))}
        </TextField>
    );
};