import React from 'react';
import useAuth from '../../hooks/useAuth';
import './index.css';

const Dashboard = () => {
    const { authenticated } = useAuth({ auth: true, path: '/dashboard' });

    return (
        <div>
            Dashboard: Authenticated: {authenticated}
        </div>
    );
}

export default Dashboard;