import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const useSdk = ({ skipSdkInit = false } = {}) => {
    const [sdkInitialized, setSdkInitialized] = useState(false);
    const [sdk, setSdk] = useState();

    useEffect(() => {
        console.log('Start SDK Initialization');
        if (!window.sdk && !skipSdkInit) {
            window.sdk = window.apigClientFactory.newClient({
                accessKey: Cookies.get('accessKey') || window.config.accessKey,
                secretKey: Cookies.get('secretKey') || window.config.secretKey,
                sessionToken: Cookies.get('sessionToken') || window.config.sessionToken,
                sessionId: Cookies.get('sessionId') || window.config.sessionId
            });
        }
        setSdk(window.sdk);
    }, [skipSdkInit]);

    useEffect(() => {
        if (sdk) {
            console.log('SDK Initialized');
            setSdkInitialized(true);
        }
    }, [sdk]);
    
    return {
        sdk,
        sdkInitialized
    }
};

export default useSdk;
