import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import isEmail from 'validator/lib/isEmail';
import deepEqual from 'deep-equal';




const AuditNotificationDialog = ({ open, auditNotficationsInitialState, userClientId, rowToEdit, setOpen, rowAction, status,
    LOCALE, dialog, auditNotificaitonList, setAuditNotificaitonList }) => {

    const [state, setState] = useState(auditNotficationsInitialState);
    const [userEmailAddress, setUserEmailAddress] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');

    useEffect(() => {
        const { clientId, emailAddress, ...auditProperties } = rowToEdit || {};
        if (rowAction === 'ADD') {
            setUserEmailAddress('');
            setState(auditNotficationsInitialState);
            return;
        } else if (rowAction === 'EDIT') {
            setUserEmailAddress(emailAddress);
            setState(auditProperties);
        }
    }, [rowToEdit, rowAction])

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const handleEmailChange = (e) => {
        e.preventDefault();
        setUserEmailAddress(e.target.value)
    };

    const openDialog = (text) => {
        dialog.setText(text);
        dialog.setNoText('');
        dialog.setYesText(LOCALE.GLOBAL.OK);
        dialog.setCls('');
        dialog.setIcon('');
        dialog.open();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailValidation = isEmail(userEmailAddress ?? '');
        // Email field validaiton 
        if (!emailValidation) {
            setEmailErrorMessage(LOCALE.AUDIT_NOTIFICATIONS.INVALID_EMAIL);
            return;
        } else {
            setEmailErrorMessage('');
        };

        const body = {
            emailAddress: userEmailAddress,
            uiAuditNotifications: state
        };

        // if audit properties are not changed then do not submit , alert user.
        if (rowAction === 'EDIT') {
            const { clientId, emailAddress, ...auditProperties } = rowToEdit || {};
            const isAuditPropertiesChanged = deepEqual(body?.uiAuditNotifications, auditProperties, { strict: false })

            if (isAuditPropertiesChanged) {
                openDialog(LOCALE.AUDIT_NOTIFICATIONS.EDIT_ALERT)
                return;
            }
        }

        // When creating a new record, one of the audit properties need to be checked. Otherwise that record will be deleted.
        // allow this only when a record is deleted.
        const isNewRecordAllowed = Object.values(state).some((value) => value === true);

        if (rowAction !== 'DELETE' && !isNewRecordAllowed) {
            openDialog(LOCALE.AUDIT_NOTIFICATIONS.ALERT)
            return;
        }

        try {
            status.open();
            const { data } = await window.sdk.auditNotificationUpdateClientidPut({ clientId: userClientId }, body);
            status.close();

            if (data?.responseMessage) {
                openDialog(LOCALE.AUDIT_NOTIFICATIONS.SUCCESS)
                console.log("Audit notifications update success:");
            };
            // After submitting new record, trigger list API
            setAuditNotificaitonList(!auditNotificaitonList);
        } catch (error) {
            status.close();
            console.log("Audit notifications update error:", error);
            openDialog(LOCALE.AUDIT_NOTIFICATIONS.ERROR)
        }

        // close the dialog
        setOpen(false);

        // Reset state
        setState(auditNotficationsInitialState);
        setUserEmailAddress('');
        setEmailErrorMessage('');

    };

    const handleCancel = () => {
        setOpen(false);

        // Reset state
        if (rowAction === 'ADD') {
            setUserEmailAddress('');
            setState(auditNotficationsInitialState);
        } else if (rowAction === 'EDIT') {
            const { clientId, emailAddress, ...auditProperties } = rowToEdit || {};
            setUserEmailAddress(emailAddress);
            setState(auditProperties);
        }
        setEmailErrorMessage('');
    };


    return (
        <Dialog open={open}>
            <div className='audit-notification-dialog' >
                <DialogContent>
                    <div className='email-address-container'>
                        <div className='email-address-label'>Email Address</div>
                        <TextField
                            autoFocus
                            id="email-address"
                            type="email"
                            fullWidth
                            variant="outlined"
                            className='email-address-field'
                            onChange={(e) => handleEmailChange(e)}
                            value={userEmailAddress || ''}
                            disabled={rowAction === 'EDIT' ? true : false}
                        />
                    </div>
                    <div className='email-address-error'>{emailErrorMessage ?? ''}</div>
                    <div className='audit-properties-container'>
                        <FormGroup>
                            {
                                Object.entries(state)?.map((row) => {
                                    return <FormControlLabel
                                        className='audit-properties'
                                        key={row[0]}
                                        control={
                                            <Checkbox
                                                checked={row[1]}
                                                onChange={handleChange}
                                                name={row[0]}
                                                color="secondary"
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 25, } }}
                                            />
                                        }
                                        label={row[0][0]?.toUpperCase() + row[0]?.slice(1)}
                                    />
                                })
                            }
                        </FormGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='dialog-actions'>
                        <Button onClick={handleCancel} color="secondary" variant="outlined" size="large">Cancel</Button>
                        <Button onClick={(e) => handleSubmit(e)} color="secondary" variant="outlined" size="large">Submit</Button>
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    )
};


export default AuditNotificationDialog;




