
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import useSdk from '../../hooks/useSdk';
import AuditNotificationDialog from './AuditNotificationDialog';
import { getSessionData } from "../../store/util";
import AddIcon from '@mui/icons-material/Add';
import './index.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { ROLES } from '../../store/roles';
import { goToTemplates } from '../../store/nav';
import { warningIcon } from '../../store/util';



const auditNotficationsInitialState = {
    "logon": false,
    "logoff": false,
    "templateCreate": false,
    "templateUpdate": false,
    "templateDelete": false,
    "templatePromote": false,
    "assetCreate": false,
    "assetUpdate": false,
    "assetDelete": false,
    "assetPromote": false
};

const AuditNotifications = ({
    dialog,
    status,
    LOCALE,
    clientId,
    roles
}) => {

    const [auditNotificationsData, setAuditNotificationsData] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null)
    const [action, setAction] = useState('')
    const { sdk, sdkInitialized } = useSdk();
    const clientList = getSessionData("clientList") || [];
    const [auditNotificaitonList, SetAuditNotificaitonList] = useState(false);


    useEffect(() => {
        getAuditNotificaitonDataFromSdk();
    }, [sdkInitialized, auditNotificaitonList, clientId])

    const getAuditNotificaitonDataFromSdk = async () => {
        try {
            if (roles === ROLES.ADMIN) {
                status.open();
                const { data } = await window.sdk.auditNotificationListClientidGet({ clientId });
                console.log("Audit notifications list success:");
                setAuditNotificationsData(data?.uiAuditNotifications);
                status.close();
            } else {
                openNotPermittedDialog(); //Users with invalid permissions will be sent back to the /templates route
            }
        }
        catch (error) {
            status.close();
            console.log("Audit notifications list error:", error);
        }
    };

    const getEditAction = (row) => {
        return <div className='table-actions'>
            <Button variant={'contained'} className='grid-button' onClick={(e) => handleEditRow(row)}>
                <EditIcon className={'icon'} />
                {LOCALE.AUDIT_NOTIFICATIONS.EDIT}
            </Button>
            <Button className="table-delete-button" variant="outlined" startIcon={<DeleteIcon />} onClick={(e) => handleDelete(row)}>
                {LOCALE.AUDIT_NOTIFICATIONS.DELETE}
            </Button>
        </div>;
    }

    const handleEditRow = (row) => {
        console.log("row", row)
        setSelectedRow(row);
        setAction('EDIT');
        setOpen(true);
    };

    const handleAddRow = () => {
        setAction('ADD');
        setOpen(true);
    };

    const openNotPermittedDialog = () => {
        dialog.setTitle(LOCALE.GLOBAL.NOT_PERMITTED_TITLE);
        dialog.setText(LOCALE.GLOBAL.NOT_PERMITTED_TEXT);
        dialog.setIcon(warningIcon());
        dialog.setCls('warning');
        dialog.setYesText(LOCALE.GLOBAL.OK);
        dialog.setYesCallback( () => {
            goToTemplates()
        });
        dialog.setNoText('');
        dialog.open();
    }

    const handleDelete = async (row) => {

        const body = {
            emailAddress: row?.emailAddress,
            uiAuditNotifications: auditNotficationsInitialState
        };

        try {
            status.open();
            const { data } = await window.sdk.auditNotificationUpdateClientidPut({ clientId }, body);
            status.close();

            if (data?.responseMessage) {
                console.log("Audit notifications record delete success:");
            };
            // After submitting new record, trigger list API
            SetAuditNotificaitonList(!auditNotificaitonList);
        } catch (error) {
            status.close();
            console.log("Audit notifications record delete error:", error);
        }
    };

    return (
        <div className='audit-notifications'>
            <div className='add-record-button-container'>
                <Button onClick={() => handleAddRow()} variant="outlined" className="addrecord-button" startIcon={<AddIcon />}>
                    {LOCALE.AUDIT_NOTIFICATIONS.ADD_RECORD}
                </Button>
            </div>
            <div className='audit-notifications-table-container'>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell key="emailAddress" align="center" >EmailAddress</TableCell>
                                {auditNotficationsInitialState && Object.keys(auditNotficationsInitialState)?.map((header) => {
                                    return <TableCell key={header} align="center">{header[0]?.toUpperCase() + header?.slice(1)}</TableCell>
                                })}
                                <TableCell key="actions" align="center" ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {auditNotificationsData && auditNotificationsData?.map((row) => {
                                return <TableRow key={row?.emailAddress}>
                                    <TableCell align="center">{row?.emailAddress}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.logon)}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.logoff)}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.templateCreate)}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.templateUpdate)}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.templateDelete)}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.templatePromote)}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.assetCreate)}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.assetUpdate)}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.assetDelete)}</TableCell>
                                    <TableCell align="center">{JSON.stringify(row?.assetPromote)}</TableCell>
                                    <TableCell align="center">
                                        {getEditAction(row)}
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div >
                <AuditNotificationDialog
                    open={open}
                    rowToEdit={selectedRow}
                    rowAction={action}
                    setOpen={setOpen}
                    clientList={clientList}
                    status={status}
                    LOCALE={LOCALE}
                    dialog={dialog}
                    setAuditNotificaitonList={SetAuditNotificaitonList}
                    auditNotificaitonList={auditNotificaitonList}
                    auditNotficationsInitialState={auditNotficationsInitialState}
                    userClientId={clientId}
                />
            </div>
        </div >
    )
};


export default AuditNotifications;

