import LocalizedStrings from "react-localization";
import EN from './en';
import IT from './it';
import FR from './fr';

const LOCALE = new LocalizedStrings({
    en: EN,
    fr: FR,
    it: IT
});

export default LOCALE;