
export const goToLogin = () => {
    window.location.href = '/';
}

export const goToLoginInit = () => {
    window.location.href = '/logininit';
}

export const goToLogoutInit = () => {
    window.location.href = '/logoutinit';
}

export const goToEdit = (navigate, location, templateName, templateVersion, isCtrl) => {
    const url = `/edit/${templateName}/${templateVersion}`;
    if (isCtrl) {
        window.open(
            url,
            '_blank',
            'rel="noreferrer"'
        );
    } else if (location.pathname === '/templates' || location.pathname === '/shared') {
        navigate(url, { state: { prevPath: location } });
    } else {
        window.location.href = url;
    }
}

export const goToEditReadOnly = (navigate, location, templateName, templateVersion, isCtrl, status) => {
    const url = `/edit/${templateName}/${templateVersion}/${status}?readonly=true`;
    if (isCtrl) {
        window.open(
            url,
            '_blank',
            'rel="noreferrer"'
        );
    } else if (location.pathname === '/templates' || location.pathname === '/shared') {
        navigate(url, { state: { prevPath: location } });
    } else {
        window.location.href = url;
    }
}

export const goToAssetEditReadOnly = (navigate, location, templateName, templateVersion, isCtrl, status) => {
    const url = `/asset/edit/${templateName}/${templateVersion}/${status}?readonly=true`;
    if (isCtrl) {
        window.open(
            url,
            '_blank',
            'rel="noreferrer"'
        );
    } else if (location.pathname === '/templates' || location.pathname === '/shared') {
        navigate(url, { state: { prevPath: location } });
    } else {
        window.location.href = url;
    }
}

export const goToAssetEdit = (navigate, location, assetName, assetVersion, isCtrl) => {
    const url = `/asset/edit/${assetName}/${assetVersion}`;
    if (isCtrl) {
        window.open(
            url,
            '_blank',
            'rel="noreferrer"'
        );
    } else if (location.pathname === '/templates' || location.pathname === '/shared') {
        navigate(url, { state: { prevPath: location } });
    } else {
        window.location.href = url;
    }
}

export const goToAssetEditPending = (assetName, assetVersion) => {
    window.location.href = `/asset/edit/${assetName}/${assetVersion}/pending`;
}

export const goToCreate = () => {
    window.location.href = '/create';
}

export const goToDashboard = () => {
    window.location.href = '/dashboard';
}

export const goToTemplates = () => {
    window.location.href = '/templates';
}

export const goToTemplatesWithStatus = (navigate, status) => {
    navigate(`/templates/${status}`);
}

export const goToShared = () => {
    window.location.href = '/shared';
}

export const goToSharedWithStatus = (navigate, status) => {
    navigate(`/shared/${status}`);
}

export const goToReports = () => {
    window.location.href = '/reports';
}

export const goTo = (url) => {
    window.location.href = url;
}

export const goToAuditNotifications = () => {
    window.location.href = '/auditNotifications';
}
