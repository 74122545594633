import React, { useEffect, useState, useRef } from 'react';
import TableCell from '@mui/material/TableCell';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import {
    goToEdit,
    goToEditReadOnly,
    goToAssetEdit,
    goToAssetEditReadOnly
} from '../../store/nav';
import { ROLES } from '../../store/roles';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

export const ExpandedTableRow = ({
    data,
    LOCALE,
    dialog,
    roles,
    status,
    getData,
    clientId,
    sdk,
    activeData,
    draftData,
    approvedData,
    reviewData,
    type,
    showExpand = true
}) => {
    const [open, setOpen] = useState(false);
    const [expandedData, setExpandedData] = useState([]);

    let userComments = "";
    const isCommentRequired = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let rowArray = [];
        // Build an array of all the templates from each of the lists
        const activeList = activeData?.filter(d => d[`${type}Name`] === data[`${type}Name`] && d[`${type}Version`] !== data[`${type}Version`]) || [];
        const draftList = draftData.filter(d => d[`${type}Name`] === data[`${type}Name`] && d[`${type}Version`] !== data[`${type}Version`]);
        const approvedList = approvedData.filter(d => d[`${type}Name`] === data[`${type}Name`] && d[`${type}Version`] !== data[`${type}Version`]);
        const reviewList = reviewData.filter(d => d[`${type}Name`] === data[`${type}Name`] && d[`${type}Version`] !== data[`${type}Version`]);

        rowArray = [...activeList, ...draftList, ...approvedList, ...reviewList];
        rowArray.sort(sortByVersion);

        setExpandedData(rowArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const sortByVersion = (a, b) => {
        return parseInt(a[`${type}Version`]) < parseInt(b[`${type}Version`]) ? -1 : 1;
    }

    const getDate = (value) => {
        return moment(value).format('LLL');
    }

    const getExpandedActions = ({ row }, showMakeActive = true) => {
        return <div>
            {roles !== ROLES.VIEWER && row[`${type}Status`] !== "REVIEW" &&
                <Button variant={'contained'} className='grid-button' onClick={(e) => handleClickRow(e, row[`${type}Name`], row[`${type}Version`])}>
                    <EditIcon className={'icon'} />
                    {LOCALE.TEMPLATE.GRID_EDIT}
                </Button>}

            {row[`${type}Status`] === "DRAFT" && roles !== ROLES.VIEWER &&
                <Button variant={'contained'} className='grid-button' onClick={() => handleClickReview(row[`${type}Name`], row[`${type}Version`])}>
                    <VisibilityIcon className={'icon'} />
                    {LOCALE.TEMPLATE.GRID_REVIEW}
                </Button>}

            {roles === ROLES.VIEWER &&
                <>
                    <Button variant={'contained'} className='grid-button' onClick={(e) => handleClickView(e, row[`${type}Name`], row[`${type}Version`], row[`${type}Status`])}>
                        <VisibilityIcon className={'icon'} />
                        {LOCALE.TEMPLATE.GRID_VIEW}
                    </Button>
                </>
            }

            {roles === ROLES.EDITOR && (row[`${type}Status`] === "REVIEW") &&
                 <>
                    <Button variant={'contained'} className='grid-button' onClick={(e) => handleClickView(e, row[`${type}Name`], row[`${type}Version`],  row[`${type}Status`])}>
                        <VisibilityIcon className={'icon'} />
                        {LOCALE.TEMPLATE.GRID_VIEW}
                    </Button>
                </>
            }

            {roles === ROLES.ADMIN && <>
                {(row[`${type}Status`] === "REVIEW" || row[`${type}Status`] === "ACTIVE") &&
                    <>
                        <Button variant={'contained'} className='grid-button' onClick={(e) => handleClickView(e, row[`${type}Name`], row[`${type}Version`],  row[`${type}Status`])}>
                            <VisibilityIcon className={'icon'} />
                            {LOCALE.TEMPLATE.GRID_VIEW}
                        </Button>
                    </>}

                {row[`${type}Status`] === "APPROVED" &&
                    <>
                        <Button variant={'contained'} className='grid-button' onClick={(e) => handleClickView(e, row[`${type}Name`], row[`${type}Version`],  row[`${type}Status`])}>
                            <VisibilityIcon className={'icon'} />
                            {LOCALE.TEMPLATE.GRID_VIEW}
                        </Button>
                        {type === "template" && <Button variant={'contained'} className='grid-button' onClick={(e) => handleActiveApprove(row[`${type}Name`], row[`${type}Version`])}>
                            <ArrowUpIcon className={'icon'} />
                            {LOCALE.TEMPLATE.GRID_MAKE_ACTIVE}
                        </Button>}
                    </>
                }
            </>}

            {roles === ROLES.ADMIN && row[`${type}Status`] === "DRAFT" && type === 'template' &&
                <Button variant={'contained'} className='grid-button' onClick={() => handleClickDelete(row[`${type}Name`], row[`${type}Version`])}>
                    <DeleteIcon className={'icon'} />
                    {LOCALE.TEMPLATE.GRID_DELETE}
                </Button>
            }
        </div>;
    }

    const handleActiveApprove = (templatename, templateVersion) => {
        isCommentRequired.current = false;
        dialog.setTitle(LOCALE.GRID_PROMOTION.ACTIVE_TITLE);
        dialog.setText('');
        dialog.setYesText(LOCALE.GLOBAL.YES);
        dialog.setNoText(LOCALE.GLOBAL.NO);
        dialog.setYesCallback(() => {
            promoteRow(templatename, templateVersion, "ACTIVATE", userComments);
        });
        dialog.setRenderComponentsMethod(() => renderActiveText(templatename, templateVersion));
        dialog.open();
    };

    const handleClickView = (e, templatename, templateVersion, status) => {
        if (type === 'asset') {
            goToAssetEditReadOnly(navigate, location, templatename, templateVersion, e.ctrlKey, status);
        } else {
            goToEditReadOnly(navigate, location, templatename, templateVersion, e.ctrlKey, status);
        }
    };

    const handleClickReview = (templatename, templateVersion) => {
        isCommentRequired.current = false;
        dialog.setTitle(`${LOCALE.GRID_PROMOTION.REVIEW_TITLE} ${type} ${LOCALE.GRID_PROMOTION.TO_REVIEW}`);
        dialog.setText('');
        dialog.setYesText(LOCALE.GLOBAL.YES);
        dialog.setNoText(LOCALE.GLOBAL.NO);
        dialog.setYesCallback(() => {
            promoteRow(templatename, templateVersion, "REVIEW", userComments);
        });
        dialog.setRenderComponentsMethod(() => renderReviewText(templatename, templateVersion));
        dialog.open();
    };

    const handleClickDelete = (templatename, templateVersion) => {
        dialog.setTitle(`${LOCALE.GRID_PROMOTION.DELETE_TITLE} ${type}?`);
        dialog.setText('');
        dialog.setYesText(LOCALE.GLOBAL.YES);
        dialog.setNoText(LOCALE.GLOBAL.NO);
        dialog.setYesCallback(() => {
            deleteRow(templatename, templateVersion);
        });
        dialog.setRenderComponentsMethod(() => renderDeleteText(templatename, templateVersion));
        dialog.open();
    };

    const handleClickRow = (e, templateName, templateVersion) => {
        if (type === 'asset') {
            goToAssetEdit(navigate, location, templateName, templateVersion, e.ctrlKey);
        } else {
            goToEdit(navigate, location, templateName, templateVersion, e.ctrlKey);
        }
    };

    const renderActiveText = (templateName, templateVersion) => {
        return (
            <>
                {LOCALE.GRID_PROMOTION.SET_ITEM_TEXT}&nbsp;
                <span className={'delete-modal-template-name'}>{templateName}</span>&nbsp;
                {LOCALE.GRID_PROMOTION.VERSION}:&nbsp;
                <span className={'delete-modal-template-name'}>{templateVersion}</span> {LOCALE.GRID_PROMOTION.TO_ACTIVE_TEXT}
                <div style={{'paddingTop': '20px'}}>{ renderUserComments() }</div>
            </>
        );
    };

    const renderReviewText = (templateName, templateVersion) => {
        return (
            <>
                {LOCALE.GRID_PROMOTION.SEND_ITEM_TEXT}&nbsp;
                <span className={'delete-modal-template-name'}>{templateName}</span>&nbsp;
                {LOCALE.GRID_PROMOTION.VERSION}:&nbsp;
                <span className={'delete-modal-template-name'}>{templateVersion}</span> {LOCALE.GRID_PROMOTION.TO_REVIEW}
                <div style={{'paddingTop': '20px'}}>{ renderUserComments() }</div>
            </>
        );
    };

    const renderDeleteText = (templateName, templateVersion) => {
        return (
            <>
                {LOCALE.GRID_PROMOTION.DELETE_ITEM_TEXT}&nbsp;
                <span className={'delete-modal-template-name'}>{templateName}</span>&nbsp;
                {LOCALE.GRID_PROMOTION.VERSION}:&nbsp;
                <span className={'delete-modal-template-name'}> {templateVersion}</span>?
            </>
        );
    };

    const renderUserComments = () => {
        return <TextField
        helperText={ isCommentRequired.current ? LOCALE.PROMOTION.COMMENT_REQUIRED_TEXT : LOCALE.PROMOTION.COMMENT_OPTIONAL_TEXT}
        multiline={true}
        autoFocus={true}
        fullWidth={true}
        id="workflow-comments"
        label={LOCALE.PROMOTION.COMMENT_LABEL}
        onChange={e => validateCommentField(e)}
    />;
    };
    
    const validateCommentField = (e) => {
        if ( !e.target.value.trim().length > 0 && isCommentRequired.current ) {
            dialog.setIsDisabled(true);
        } else {
            dialog.setIsDisabled(false);
            userComments = e.target.value;
        }
    };

    const promoteRow = async (templatename, templateVersion, templateStatus, comments) => {
        const body = {};

        try {
            status.open();
            if (type === 'template') {
                body.promotion = templateStatus;
                body[`${type}NameVersion`] = `${templatename}-${templateVersion}`;
                body.templateName = templatename;
                body.templateVersion = templateVersion;
                body.comments = comments;
                await sdk[`${type}PromotionClientidTemplatenamePost`]({ templatename, clientid: clientId }, body);
            } else {
                body.promotion = templateStatus;
                body.templatesToUpdate = [];
                body.assetName = templatename;
                body.assetVersion = templateVersion;
                body.comments = comments;
                await sdk[`${type}ApproveClientidPost`]({ templatename, clientId }, body);
            }
            getData();
        }
        catch (e) {
            getData();
        }
    };

    const deleteRow = async (templatename, templateVersion) => {
        try {
            status.open();
            if (type === 'template') {
                const tempName = `${templatename}-${templateVersion}`;
                await sdk[`${type}DeleteClientidTemplatenameDelete`]({ templatename: tempName, clientId });
            } else {
                await sdk[`${type}DeleteClientidTemplatenameDelete`]({ templatename: templatename, clientId });
            }
            getData();
        }
        catch (e) {
            getData();

            dialog.setTitle(LOCALE.GLOBAL.ERROR);
            dialog.setText(`${LOCALE.GLOBAL.DELETE_ERROR} ${type}`);
            dialog.setYesText(LOCALE.GLOBAL.OK);
            dialog.setNoText('');
            dialog.setYesCallback(() => { });
            dialog.open();
        }
    };

    const renderExpandedRows = (rowArray) => {
        return (
            <>
                {rowArray.map((r, idx) => <TableRow key={`expandedRow_${idx}`}>
                    {showExpand && <TableCell></TableCell>}
                    <TableCell>{r[`${type}Name`]}</TableCell>
                    <TableCell>{r.author}</TableCell>
                    <TableCell>{r[`${type}Version`]}</TableCell>
                    <TableCell>{r[`${type}Status`]}</TableCell>
                    <TableCell>{getDate(r.timestamp * 1000 || r.createdDt)}</TableCell>
                    <TableCell align="right">
                        {getExpandedActions({ row: r }, (type === "asset") ? false : true)}
                    </TableCell>
                </TableRow>
                )}
            </>
        );
    }

    return (
        <>
        <TableBody>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                {showExpand && <TableCell>
                    {data[`${type}Version`] >= 0 && expandedData.length > 0 && <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>}
                </TableCell>}
                <TableCell>
                    {data[`${type}Name`]}
                </TableCell>
                <TableCell>
                    {data.author}
                </TableCell>
                <TableCell>
                    {data[`${type}Version`]}
                </TableCell>
                <TableCell>
                    {data[`${type}Status`] || 'Active'}
                </TableCell>
                <TableCell>
                    {getDate(data.createdDt || data.timestamp * 1000)}
                </TableCell>
                <TableCell align="right">
                    {getExpandedActions({ row: data }, false)}
                </TableCell>
            </TableRow>
        </TableBody>
            {open && showExpand && expandedData.length > 0 && <TableRow>
                <TableCell colSpan={7}>

                    <TableContainer component={Paper} style={{ background: '#eeeeee', width: '100%' }}>
                        <Table aria-label="table">
                            <TableHead sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell></TableCell>
                                <TableCell component="th" scope="row">
                                    Name
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Author
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Version
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    State
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Created
                                </TableCell>
                                <TableCell></TableCell>
                            </TableHead>
                            {renderExpandedRows(expandedData)}
                        </Table>
                    </TableContainer>

                </TableCell>
            </TableRow>}
        </>
    );
}