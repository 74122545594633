import { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { goToTemplates, goToLogin, goToLogoutInit } from '../store/nav';
import {
    getUserName,
    getClientId,
    getUserRoles,
    getUserType,
    getMultiClientId
} from '../store/util';

import LOCALE from '../localization/local';

const LOGOUT_TIME = 3300000; //Setting logout time to allow tokens to be refreshed before expiration (55 minutes)
const TOKEN_EXPIRATION = 3600000; //Setting time to automatically log a user out if they are idle (One hour)
const useAuth = ({
    auth,
    path, 
    dialog
}) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [authComplete, setAuthComplete] = useState(false);
    const [sessionId] = useState(Cookies.get('sessionId') || window.config.sessionId);
    const [userName] = useState(getUserName(Cookies.get('userName') || window.config.userName));
    const [userType] = useState(getUserType(Cookies.get('userName') || window.config.userName));
    const [roles] = useState(getUserRoles(Cookies.get('userRoles') || window.config.userRoles));
    const [clientId, setClientId] = useState(getClientId(Cookies.get('clientId') || window.config.clientId));
    const [multiClientId] = useState(getMultiClientId(Cookies.get('clientId') || window.config.clientId));
    const autoLogoutTimer = useRef(null);
    let displayModalTimer;
    
    useEffect(() => {
        if (auth) {
            checkAuth();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    useEffect(() => {
        if (authComplete && auth && !authenticated) {
            goToLogin();
        }
        if (!auth && sessionId && path === '/') {
            goToTemplates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authComplete, auth, authenticated]);

    const setClientIdValue = (value) => {
        setClientId(value);
        Cookies.set('clientId', value, { path: '/', domain: '.precisionecare.com' });
    }

    const checkAuth = () => {
        setLogoutTimer();
        setAuthenticated(true);
        // setAuthenticated(sessionId);
        setAuthComplete(true);
    }

    const refreshAuth = async () => {
        const url = `${window.config.authUrl}/refresh`;
        clearTimeout(autoLogoutTimer.current); 
        autoLogoutTimer.current = null;
        
        try {
            const res = await window.axios({
                method: 'GET',
                url,
                headers: { 'x-csg-session': document.cookie }
            });

            return res;
        }
        catch (e) {
            goToLogoutInit();
        }
        
    }

    const setLogoutTimer = () => {
        clearTimeout(displayModalTimer);
        displayModalTimer = setTimeout(alertLogout, LOGOUT_TIME);

        autoLogoutTimer.current = setTimeout(() => {
            goToLogoutInit();
        }, TOKEN_EXPIRATION);
    };

    const alertLogout = () => {
        dialog.setTitle(LOCALE.LOGOUT.TITLE);
        dialog.setText(LOCALE.LOGOUT.TEXT);
        dialog.setYesText(LOCALE.LOGOUT.YES_TEXT);
        dialog.setNoText(LOCALE.LOGOUT.NO_TEXT);
        dialog.setNoCallback(() => {
            goToLogoutInit();
        });
        dialog.setYesCallback(() => {
            refreshAuth();
        });
        dialog.open();
    }

    return {
        authenticated,
        refreshAuth,
        sessionId,
        userName,
        roles,
        userType,
        clientId,
        author: userName,
        setClientIdValue,
        multiClientId
    }
};

export default useAuth;