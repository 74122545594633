export const getTemplate = () => {
    const baseTemplate = 'https://rsrc.getbee.io/api/templates/m-bee';
    return fetch(baseTemplate)
    .then((response) => response.json());
}

export const updateHTML = async (page, token) => {
    let url = "https://rc-bee-multiparser.getbee.io/api/v3/parser/email?p=false&b=false&useNewBumper=true";
    
    const updateRequest = await window.axios({
        method: 'POST',
        url,
        headers: { 
            'Authorization': `Bearer ${token}`
        },
        data: {
            page

        }
    });

    return updateRequest;
};

export const refreshBeeAuth = async (refreshToken) => {
    let url = "https://bee-auth.getbee.io/refresh";
    try {
        const refreshRequest = await window.axios({
            method: 'POST',
            url,
            data: {
                token: refreshToken
            }
        });

        return refreshRequest;

    } catch(e) {
        console.log(e); 
    }
};


export const startEditor = async ({ token, config }, template, editor) => {
    await editor.start(config, template);
    return editor;
}