// KEEP THIS LIST IN ALPHABETICAL ORDER

const IT = {
    ACCOUNT: {
        LOGIN: "Login-ITALIAN",
        LOGIN_INIT: "Login Init-ITALIAN",
        LOGGED_OUT: "Logged Out-ITALIAN",
        LOGOUT: "Logout-ITALIAN"
    },
    CONTENT: {
        SHARED_ASSETS: "Shared Assets-ITALIAN",
        SHARED_CONTENT: "Shared Content-ITALIAN",
    },
    DASHBOARD: {
        TITLE: "Dashboard-ITALIAN"
    },
    GLOBAL: {
        DELETE: "Delete-ITALIAN",
        FILTER: "Filter-ITALIAN",
        YES: "Yes-ITALIAN",
        NO: "No-ITALIAN"
    },
    REPORTING: {
        TITLE: "Reporting-ITALIAN"
    },
    ROW: {
        NAME: "Row Name-ITALIAN",
        SAVING: "Saving Row-ITALIAN",
    },
    TEMPLATE: {
        ACTIONS: "Actions-ITALIAN",
        AUTHOR: "Author-ITALIAN",
        CREATE_NEW: "Create New Template-ITALIAN",
        CREATED: "Created-ITALIAN",
        DELETE: "Are you sure you want to delete {templatename}?",
        EDIT: "Edit Template-ITALIAN",
        FILENAME: "Filename-ITALIAN",
        MODIFY: "Modify Template-ITALIAN",
        NAME: "Template Name-ITALIAN",
        PROPERTIES: {
            FROM: "From-ITALIAN",
            SUBJECT: "Subject-ITALIAN",
            TITLE: "Properties-ITALIAN",
            TO: "To-ITALIAN"
        },
        SEARCH: "Search Templates-ITALIAN",
        TEMPLATE_VERSION: "Template Version-ITALIAN",
        VERSION: "Version-ITALIAN"
    },
    AUDIT_NOTIFICAITONS: {
        TITLE: "Audit Notifications-ITALIAN",
        ERROR: "Error submitting data-ITALIAN",
        SUCCESS: "Data submitted successfully-ITALIAN",
        ALERT: "Please select one audit notification-ITALIAN",
        EDIT_ALERT: "Please edit atleast one audit notification-ITALIAN",
        ADD_RECORD: "ADD NOTIFICATION-ITALIAN",
        EDIT: "EDIT-ITALIAN",
        DELETE: "DELETE-ITALIAN"
    }
};

export default IT;