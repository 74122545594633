import { useEffect } from 'react';
import { goTo } from '../../store/nav';
import './index.css';

const LOGIN_INIT = `${window.config.authUrl}/init`;

const LoginInit = () => {
  useEffect(() => {
    goTo(LOGIN_INIT);
  }, []);

  return (
    <div>
      Initializing Login Sequence...
    </div>
  );
}

export default LoginInit;
