import React, { useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './index.css';

export const MainMenu = ({ children, items = [] }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (val, item) => {
        setAnchorEl(null);
        item.action(val);
    };

    return (
        <div>
            <div onClick={handleClick}>
                {children}
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                >
                <MenuItem disabled={true} className="main-menu-item"><hr /></MenuItem>
                {items.map(item => 
                    <MenuItem key={item.name} onClick={(val) => handleItemClick(val, item)} className="main-menu-item">
                        {item.name}
                    </MenuItem>
                )}

            </Menu>
        </div>
    );
};