import React from "react";
import './index.css';
export const AppVersion = () => {
    const info = process.env.REACT_APP_VERSION;
    if (!info) {
        console.info("No version information present at build time.");
        return <></>;
    }
    console.info(`version: ${info}`);
    const getSimpleVersion = (info) => {
        return info.split('+')[0];
    }

    return <div className="version-info" title={info}>version: {getSimpleVersion(info)}</div>;
};