import { useState } from 'react';

const useDialog = ({ LOCALE }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [icon, setIcon] = useState(() => {});
    const [cls, setCls] = useState('');
    const [yesText, setYesText] = useState(LOCALE.GLOBAL.YES);
    const [noText, setNoText] = useState(LOCALE.GLOBAL.NO);
    const [unsyncedText, setUnsyncedText] = useState('');
    const [yesCallback, setYesCallbackHandler] = useState(() => () => {});
    const [noCallback, setNoCallbackHandler] = useState(() => () => {});
    const [unsyncedCallback, setUnsyncedCallbackHandler] = useState(() => () => {});
    const [renderDialogComponents, setRenderDialogComponents] = useState(() => () => {});
    
    const open = () => {
        setIsOpen(true);
    };
    
    const close = (e, reason) => {
        if (reason !== "backdropClick") {
            //Need to reset the callback functions on close to alleviate unwanted behaviors in separate modals
            setUnsyncedCallbackHandler(() => () => {});
            setNoCallbackHandler(() => () => {});
            setYesCallbackHandler(() => () => {});
            setIsDisabled(false);
            setIsOpen(false);
        }
    };

    const setYesCallback = func => {
        setYesCallbackHandler(() => func);
    }

    const setNoCallback = func => {
        setNoCallbackHandler(() => func);
    }

    const setUnsyncedCallback = func => {
        setUnsyncedCallbackHandler(() => func);
    }

    const setRenderComponentsMethod = func => {
        setRenderDialogComponents(() => func);
    }
    
    
    return {
        dialog: {
            open,
            isOpen,
            setTitle,
            title,
            setText,
            text,
            setIcon,
            icon,
            setCls,
            cls,
            setIsDisabled, 
            isDisabled,
            setYesText,
            yesText,
            setNoText,
            noText,
            setUnsyncedText,
            unsyncedText,
            setYesCallback,
            yesCallback,
            setNoCallback,
            noCallback,
            setUnsyncedCallback,
            unsyncedCallback,
            setRenderComponentsMethod,
            renderDialogComponents,
            close
        }
    }
};

export default useDialog;