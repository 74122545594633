// KEEP THIS LIST IN ALPHABETICAL ORDER

const EN = {
    ACCOUNT: {
        LOGIN: "Login",
        LOGIN_INIT: "Login Init",
        LOGGED_OUT: "Logged Out",
        LOGOUT: "Logout"
    },
    ASSET: {
        APPROVE: "Approve",
        CREATE: {
            ERROR_TITLE: "Unable To Create Asset",
            ERROR_TEXT: "This asset name is already in use. Please select a different asset name.",
            TEXT: "Input a name for the asset",
            TITLE: "Create Asset"
        },
        EDIT: "Edit",
        LOAD: {
            ERROR: "There was an error loading this asset",
            ERROR_TITLE: "Asset Load Error"
        },
        NAME: "Asset Name",
        REJECT: "Reject Changes",
        SEARCH: "Search Assets",
        SHOW_PENDING: "Show Pending Assets",
        VIEW_TEMPLATES: "View Templates Affected",
        VIEW_TEMPLATES_EMPTY: "No Templates Affected"
    },
    AUDIT_NOTIFICATIONS: {
        ADD_RECORD: "ADD NOTIFICATION",
        ALERT: "Please select one audit notification",
        DELETE: "DELETE",
        EDIT: "EDIT",
        EDIT_ALERT: "Please edit atleast one audit notification",
        ERROR: "Error submitting data",
        INVALID_EMAIL: "Please enter a valid email address",
        SUCCESS: "Data submitted successfully",
        TITLE: "Audit Notifications"
    },
    CONTENT: {
        SHARED_ASSETS: "Shared Assets",
        SHARED_CONTENT: "Shared Content",
    },
    DASHBOARD: {
        TITLE: "Dashboard"
    },
    GLOBAL: {
        CANCEL: "Cancel",
        CREATE: "Create",
        DELETE: "Delete",
        DELETE_ERROR: "Unable to delete",
        ERROR: "Error",
        FILTER: "Filter",
        NO: "No",
        NOT_PERMITTED_TEXT: 'Your current role does not allow for permission to access this resource.',
        NOT_PERMITTED_TITLE: 'You do not have appropriate permissions to access this page!',
        OK: "OK",
        YES: "Yes"
    },
    GRID_PROMOTION: {
        ACTIVE_TITLE: "Set as active template?",
        DELETE_TITLE: "Delete",
        REVIEW_TITLE: "Send",
        DELETE_ITEM_TEXT: "Do you want to delete",
        SEND_ITEM_TEXT: "Do you want to send",
        SET_ITEM_TEXT: "Do you want to set",
        TO_ACTIVE_TEXT: "as the active template?",
        TO_REVIEW: "to review?",
        VERSION: "version"
    },
    HELP: {
        ARIA: "Click to access help documentation"
    },
    LOGIN: {
        TEXT: "Click to login to message editor",
        TITLE: "Welcome",
        YES_TEXT: "Login"
    },
    LOGOUT: {
        NO_TEXT: "Logout",
        TEXT: "Your session is about to expire. Would you like to logout, or continue your session?",
        TITLE: "Session Expiring",
        YES_TEXT: "Continue Session"
    },
    NAVIGATE_AWAY: {
        TEXT: "You have unsaved changes.  Are you sure you want to leave?",
        TITLE: "Unsaved Changes"
    },
    PROMOTION: {
        APPROVE_TEXT: "Do you want to approve",
        COMMENT_LABEL: "Add Comment",
        COMMENT_REQUIRED_TEXT: "Comment is Required",
        COMMENT_OPTIONAL_TEXT: "Comment is Optional",
        DEACTIVATE_TEXT: "Do you want to deactivate",
        DISAPPROVE_TEXT: "Do you want to disapprove",
        DISAPPROVE_OVERWRITE_TEXT: "Caution: Disapproving this template will overwrite the current template in DRAFT!",
        REJECT_TEXT: "Do you want to reject",
        REJECT_OVERWRITE_TEXT: "Caution: Rejecting this template will overwrite the current template in DRAFT!",
        VERSION: "version"
    },
    REPORTING: {
        DOWNLOAD: "Download",
        END_DATE_HELPER: "Must be greater than or equal to start date",
        END_DATE_LABEL: "End Date",
        NO_ASSET_DATA: "No Assets Found",
        NO_TEMPLATE_DATA: "No Templates Found",
        SUCCESS_TITLE: "Success",
        START_DATE_HELPER: "Must be less than or equal to end date",
        START_DATE_LABEL: "Start Date",
        TEXT: "Your report will be available soon for download. Please check back later",
        TITLE: "Reporting"
    },
    ROW: {
        NAME: "Save New Asset",
        SAVING: "Saving Row"
    },
    SHARED: {
        APPROVE: "Approve",
        APPROVED: "Approved",
        APPROVED_TITLE: "This asset has been approved",
        AUTHOR: "Author",
        COUNT: "Templates Affected",
        CREATED: "Created",
        EDIT: "Edit",
        ERROR: "Error retrieving assets for selected client",
        ERROR_TITLE: "Error",
        NAME: "Asset Name",
        REVIEW: "Review",
        STATUS: "Status",
        VERSION: "Version",
        VIEW: "View"
    },
    SYNCED: {
        BEE_DESCRIPTION: "Click on the button to view available row options.",
        BEE_LABEL: "Row Options",
        BEE_NOT_PERMITTED: "You do not have permission to edit this Shared Asset",
        CANCEL: "Cancel",
        EDIT_ASSET: "Edit Asset",
        TEXT: "All unsaved changes will be lost if Edit Asset is selected!",
        TITLE: "Synced Row Options",
        UNSYNC_TEXT: "Unsync Row"
    },
    TEMPLATE: {
        ACTIONS: "Actions",
        AFFECTED: "This is a list of templates using this asset",
        AFFECTED_TITLE: "Templates",
        AUTHOR: "Author",
        AUTOSAVE_TEXT: "AUTOSAVED AT ",
        CREATE: {
            ERROR: "This template name is already in use.  Please select a different template name.",
            ERROR_TITLE: "Template Name Invalid",
            ON_SAVE_ERROR: "Unable To Save Template"
        },
        CREATE_NEW: "Create Template",
        CREATED: "Created",
        DELETE: "Are you sure you want to delete ",
        EDIT: "Edit Template",
        FILENAME: "Filename",
        TEMPLATE_NAME: "Template Name",
        GRID_APPROVE: "Approve",
        GRID_DEACTIVATE: "Deactivate",
        GRID_DISAPPROVE: "Disapprove",
        GRID_DELETE: "Delete",
        GRID_EDIT: "Edit",
        GRID_MAKE_ACTIVE: "Make Active",
        GRID_REJECT: "Reject",
        GRID_REVIEW: "Review",
        GRID_VIEW: "View",
        LOAD: {
            ERROR: "There was an error loading this template",
            ERROR_TITLE: "Template Load Error"
        },
        TEMPLATES: "Templates",
        NAME: "Template Name",
        PREVIEW: {
            SHOW_HTML: "Show HTML",
            ERROR: "There was an error loading the preview",
            ERROR_TITLE: "Template Preview Error",
            EXIT_PREVIEW: "Exit Preview",
            EXIT_TEST_SEND: "Exit Test Send"
        },
        PROPERTIES: {
            DEFAULTS: {
                FROM_EMAIL_LOCAL: "Default_From_Email_Local_Part",
                FROM_NAME: "Default From Name",
                REPLY_TO: "DefaultReplyTo@mailnull.com",
                SUBJECT: "Default Subject",
            },
            FROM: "From",
            FROM_EMAIL_LOCAL: "From Email Local Part",
            FROM_NAME: "From Name",
            REPLY_TO: "Reply To",
            SUBJECT: "Subject",
            TITLE: "Properties",
            TO: "To"
        },
        RECIPIENT_EMAIL: "Recipient Email",
        SAVE: "Save",
        SEARCH: "Search Templates",
        SEND_TEST_EMAIL: "Send Test Email",
        SETTINGS: {
            NAME: "Name",
            VALUE: "Value"
        },
        SHOW_PREVIEW: "Preview",
        TEMPLATE_VERSION: "Template Version",
        VERSION: "Version",
        SAVEAS: {
            TITLE: "Copy Template",
            TEXT: "Please enter templateName to be saved as",
            NOTEXT: "Cancel",
            YESTEXT: "Save",
            BUTTONTEXT: "COPY TEMPLATE",
        }
    },
    USER: {
        CLIENT_ID: {
            SELECT: 'Select Client',
            TITLE: 'Client List'
        },
        CLIENT_ID_SET: {
            TITLE: 'Client List',
            TEXT: 'Select a different client',
            CANCEL: 'Cancel'
        }
    }
};

export default EN;