import React from "react";
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Cancel';
import './index.css';

export const Search = ({ label, value, onChange }) => {
    const textStyles = {
        marginRight: 10,
        marginLeft: 10,
        width: '300px',
        flex: 1
    };

    const handleClearSearch = () => {
        onChange({ target: { value: ''}});
    }

    return (
        <div className={'search'}>
            <div className={'input'}>
                {value && <CloseIcon title="Clear Search" className={'search-clear'} onClick={handleClearSearch} />}
                {!value && <SearchIcon className={'search-icon'} />}
                <TextField
                    label={label}
                    id="search-templates"
                    value={value}
                    onChange={onChange}
                    margin="none"
                    style={textStyles}
                    fullWidth
                    sx={{
                        width: '35ch',
                        }}
                    />
            </div>
        </div>
    );
};