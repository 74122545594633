import { useEffect } from 'react';
import { goTo } from '../../store/nav';
import './index.css';

const LOGOUT_INIT = `${window.config.authUrl}/logout`;

const LogoutInit = () => {
  useEffect(() => {
    goTo(LOGOUT_INIT);
  }, []);

  return (
    <>
      Initializing Logout...
    </>
  );
}

export default LogoutInit;
