import React, { useState, useEffect, useRef } from "react";
import './index.css';
import HelpIcon from '@mui/icons-material/Help';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import { MainMenu } from '../MainMenu';
import { Picker } from '../Picker';
import useSdk from '../../hooks/useSdk';
import {
    getSessionData,
    setSessionData
} from "../../store/util";
import { USER_TYPE } from "../../store/roles";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { goToAuditNotifications } from '../../store/nav';
import { ROLES } from '../../store/roles';

export const Header = ({
    authenticated,
    LOCALE,
    dialog,
    changeLanguage,
    showLanguages,
    userName,
    roles,
    userType,
    clientId,
    setClientIdValue,
    multiClientId
}) => {
    const navigate = useNavigate();
    const nonManagedList = useRef();
    const clientList = getSessionData("clientList") || [];
    const [clientIdList, setClientIdList] = useState(clientList);

    const { sdkInitialized } = useSdk({ skipSdkInit: (clientList.length > 0) });

    useEffect(() => {
        if (sdkInitialized) {
            getClientIdDataFromSdk();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sdkInitialized]);

    const getClientIdDataFromSdk = async () => {
        try {
            const { data } = await window.sdk.clientListGet({});
            const { availableClientList } = data;
            setClientIdList(availableClientList);
            setSessionData("clientList", availableClientList);
        }
        catch (e) {

        }
    };

    const changeClientId = (e) => {
        const { value } = e.target;
        setClientIdValue(value);
        dialog.close();
    }

    const openClientId = () => {
        dialog.setTitle(LOCALE.USER.CLIENT_ID_SET.TITLE);
        dialog.setText(LOCALE.USER.CLIENT_ID_SET.TEXT);
        dialog.setNoText(LOCALE.USER.CLIENT_ID_SET.CANCEL);
        dialog.setYesText('');
        dialog.setRenderComponentsMethod(renderClientIdPicker);
        dialog.open();
    }

    const renderClientIdPicker = () => {
        if (userType === USER_TYPE.MANAGED) {
            return (
                <Picker
                    items={clientIdList}
                    value={clientId}
                    action={changeClientId}
                    label={LOCALE.USER.CLIENT_ID.TITLE} />
            );
        } else if (multiClientId) {
            filterClients();
            return (
                <Picker
                    items={nonManagedList.current}
                    value={clientId}
                    action={changeClientId}
                    label={LOCALE.USER.CLIENT_ID.TITLE} />
            );
        }
    }

    const renderClientName = (clientId) => {
        return clientIdList.find(c => c.id === clientId)?.name;
    }

    const handleLogout = () => {
        navigate('/logoutinit');
    }

    const menuItems = [{
        name: "Logout",
        action: handleLogout
    }];

    if (userType === USER_TYPE.MANAGED || multiClientId) {
        menuItems.unshift({
            name: "Client",
            action: openClientId
        });
    }

    const handleNotifications = () => {
        console.log("handle")
        goToAuditNotifications();
    }
    
    const filterClients = () => {
        let tempArr = [];
        for (let x = 0; x < multiClientId.length; x++) {
            if (clientIdList.some(c => c.id === multiClientId[x])) {
                tempArr.push({id: multiClientId[x], name: renderClientName(multiClientId[x])});
            }
        }
        nonManagedList.current = tempArr;
        
    }

    return (
        <Box className={'header'}>
            {showLanguages && <select onChange={changeLanguage}>
                <option value='en'>ENGLISH</option>
                <option value='it'>ITALIAN</option>
                <option value='fr'>FRENCH</option>
            </select>}

            {!authenticated && <div className={'header-login'}>
                <Link to='/logininit' className={'header-link'}>
                    {LOCALE.ACCOUNT.LOGIN}
                </Link>
            </div>}

            {authenticated &&
                <>
                    <div className={'header-account'}>
                        <div className={'header-name'}>
                            {userName}
                        </div>
                        <div className={'header-role'}>
                            {renderClientName(clientId)}
                        </div>
                    </div>

                    <MainMenu items={menuItems}>
                        <PersonIcon className={'header-icon'} />
                    </MainMenu>

                    {roles === ROLES.ADMIN && <div onClick={handleNotifications}>
                        <NotificationsIcon className="notifications-icon" />
                    </div>}
                </>
            }

            <div>
                <a href="https://mycsg-docs-prod1.azurewebsites.net/MessageEditor/doclib/MessageEditor/Content/Home.htm" target="_blank" rel="noreferrer" aria-label={LOCALE.HELP.ARIA}>
                    <HelpIcon className={'header-icon'} />
                </a>
            </div>
        </Box>
    );
};