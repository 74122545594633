
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import LOCALE from '../localization/local';
import Base from './Base';
import Dashboard from './Dashboard';
import Create from './Create';
import LoggedOut from './LoggedOut';
import LoginInit from './LoginInit';
import Login from './Login';
import LogoutInit from './LogoutInit';
import Templates from './Templates';
import Shared from './Shared';
import Reports from './Reports';
import { ROLES } from '../store/roles';
import createIcon from '../assets/create.png';
import templatesIcon from '../assets/templates.png';
import sharedIcon from '../assets/shared.png';
import reportingIcon from '../assets/reporting.png';
import AuditNotifications from './AuditNotifications';

import {
    goToCreate,
    goToDashboard,
    goToTemplates,
    goToTemplatesWithStatus,
    goToShared,
    goToReports,
    goToLoginInit,
    goToLogoutInit,
    goToAuditNotifications
} from '../store/nav';

const AUTH_REQUIRED = true;
const NO_AUTH_REQUIRED = false;

const CustomRoutes = () => {
    const [lang, setLang] = useState('en');

    const changeLanguage = (e) => {
        setLang(e.target.value);
    }

    LOCALE.setLanguage(lang);

    const routes = [
        {
            "name": LOCALE.ACCOUNT.LOGIN,
            "component": Login,
            "path": "/",
            "auth": NO_AUTH_REQUIRED,
            "action": goToLoginInit,
            "show": false
        },
        {
            "name": LOCALE.ACCOUNT.LOGIN_INIT,
            "component": LoginInit,
            "path": "/logininit",
            "auth": NO_AUTH_REQUIRED,
            "show": false
        },
        {
            "name": LOCALE.ACCOUNT.LOGGED_OUT,
            "component": LoggedOut,
            "path": "/loggedout",
            "auth": NO_AUTH_REQUIRED,
            "show": false
        },
        {
            "name": LOCALE.DASHBOARD.TITLE,
            "component": Dashboard,
            "path": "/dashboard",
            "auth": AUTH_REQUIRED,
            "action": goToDashboard,
            "show": false
        },
        {
            "name": LOCALE.TEMPLATE.CREATE_NEW,
            "component": Create,
            "path": "/create",
            "auth": AUTH_REQUIRED,
            "action": goToCreate,
            "icon": createIcon,
            "roles": [ROLES.ADMIN, ROLES.EDITOR]
        },
        {
            "name": LOCALE.TEMPLATE.EDIT,
            "component": Create,
            "path": "/edit/:templateName/:templateVersion",
            "auth": AUTH_REQUIRED,
            "show": false,
            "roles": [ROLES.ADMIN, ROLES.EDITOR]
        },
        {
            "name": LOCALE.TEMPLATE.EDIT,
            "component": Create,
            "path": "/edit/:templateName/:templateVersion/:templateStatus",
            "auth": AUTH_REQUIRED,
            "show": false,
            "roles": [ROLES.ADMIN, ROLES.EDITOR]
        },
        {
            "name": LOCALE.ASSET.EDIT,
            "component": Create,
            "path": "/asset/edit/:assetName/:assetVersion",
            "auth": AUTH_REQUIRED,
            "show": false,
            "roles": [ROLES.ADMIN, ROLES.EDITOR]
        },
        {
            "name": LOCALE.ASSET.EDIT,
            "component": Create,
            "path": "/asset/edit/:assetName/:assetVersion/:assetStatus",
            "auth": AUTH_REQUIRED,
            "show": false,
            "roles": [ROLES.ADMIN, ROLES.EDITOR]
        },
        {
            "name": LOCALE.TEMPLATE.TEMPLATES,
            "component": Templates,
            "path": "/templates",
            "auth": AUTH_REQUIRED,
            "action": goToTemplates,
            "icon": templatesIcon,
            "roles": [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]
        },
        {
            "component": Templates,
            "path": "/templates/active",
            "auth": AUTH_REQUIRED,
            "roles": [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
            "show": false
        },
        {
            "component": Templates,
            "path": "/templates/approved",
            "auth": AUTH_REQUIRED,
            "roles": [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
            "show": false
        },
        {
            "component": Templates,
            "path": "/templates/draft",
            "auth": AUTH_REQUIRED,
            "roles": [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
            "show": false
        },
        {
            "component": Templates,
            "path": "/templates/review",
            "auth": AUTH_REQUIRED,
            "roles": [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
            "show": false
        },
        {
            "name": LOCALE.CONTENT.SHARED_ASSETS,
            "component": Shared,
            "path": "/shared",
            "auth": AUTH_REQUIRED,
            "action": goToShared,
            "icon": sharedIcon
        },
        {
            "component": Shared,
            "path": "/shared/approved",
            "auth": AUTH_REQUIRED,
            "roles": [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
            "show": false
        },
        {
            "component": Shared,
            "path": "/shared/draft",
            "auth": AUTH_REQUIRED,
            "roles": [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
            "show": false
        },
        {
            "component": Shared,
            "path": "/shared/review",
            "auth": AUTH_REQUIRED,
            "roles": [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
            "show": false
        },
        {
            "name": LOCALE.AUDIT_NOTIFICATIONS.TITLE,
            "component": AuditNotifications,
            "path": "/auditNotifications",
            "auth": AUTH_REQUIRED,
            "action": goToAuditNotifications,
            "show": false
        },
        {
            "name": LOCALE.REPORTING.TITLE,
            "component": Reports,
            "path": "/reports",
            "auth": AUTH_REQUIRED,
            "action": goToReports,
            "icon": reportingIcon,
            "roles": [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]
        },
        {
            "name": LOCALE.ACCOUNT.LOGOUT,
            "component": LogoutInit,
            "path": "/logoutinit",
            "auth": AUTH_REQUIRED,
            "action": goToLogoutInit,
            "show": false
        }
    ];

    return (
        <Routes>
            {routes.map(r =>
                <Route
                    key={r.path}
                    path={r.path}
                    element={<Base {...{ changeLanguage, routes, LOCALE }} auth={r.auth} path={r.path}><r.component /></Base>}
                />
            )}
        </Routes>
    );
}

export default CustomRoutes;
