// KEEP THIS LIST IN ALPHABETICAL ORDER

const FR = {
    ACCOUNT: {
        LOGIN: "Login-FRENCH",
        LOGIN_INIT: "Login Init-FRENCH",
        LOGGED_OUT: "Logged Out-FRENCH",
        LOGOUT: "Logout-FRENCH"
    },
    CONTENT: {
        SHARED_ASSETS: "Shared Assets-FRENCH",
        SHARED_CONTENT: "Shared Content-FRENCH",
    },
    DASHBOARD: {
        TITLE: "Dashboard-FRENCH"
    },
    GLOBAL: {
        DELETE: "Delete-FRENCH",
        FILTER: "Filter-FRENCH",
        YES: "Yes-FRENCH",
        NO: "No-FRENCH"
    },
    REPORTING: {
        TITLE: "Reporting-FRENCH"
    },
    ROW: {
        NAME: "Row Name-FRENCH",
        SAVING: "Saving Row-FRENCH",
    },
    TEMPLATE: {
        ACTIONS: "Actions-FRENCH",
        AUTHOR: "Author-FRENCH",
        CREATE_NEW: "Create New Template-FRENCH",
        CREATED: "Created-FRENCH",
        DELETE: "Are you sure you want to delete {templatename}?",
        EDIT: "Edit Template-FRENCH",
        FILENAME: "Filename-FRENCH",
        MODIFY: "Modify Template-FRENCH",
        NAME: "Template Name-FRENCH",
        PROPERTIES: {
            FROM: "From-FRENCH",
            SUBJECT: "Subject-FRENCH",
            TITLE: "Properties-FRENCH",
            TO: "To-FRENCH"
        },
        SEARCH: "Search Templates-FRENCH",
        TEMPLATE_VERSION: "Template Version-FRENCH",
        VERSION: "Version-FRENCH"
    },
    AUDIT_NOTIFICAITONS: {
        TITLE: "Audit Notifications-FRENCH",
        ERROR: "Error submitting data-FRENCH",
        SUCCESS: "Data submitted successfully-FRENCH",
        ALERT: "Please select one audit notification-FRENCH",
        EDIT_ALERT: "Please edit atleast one audit notification-FRENCH",
        ADD_RECORD: "ADD NOTIFICATION-FRENCH",
        EDIT: "EDIT-FRENCH",
        DELETE: "DELETE-FRENCH"
    }
};

export default FR;