import React from "react";
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import './index.css';
import TableRow from '@mui/material/TableRow';
export const ExpandedTableHeader = ({reviewColumn, columns, dataType, sortDir, setSortDir, sortDirName, setSortDirName}) => {
    const nameSort = (row, columnName) => {
        if (!row.sort) {
            return false;
        }
        setSortDirName(columns.find(c => c.headerName === columnName).field);
        setSortDir(sortDir === "ASC" ? "DESC" : "ASC");
    }

    return (
    <TableHead sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableRow>
            {dataType !== reviewColumn && <TableCell></TableCell>}
            {columns.map((c, idx) => {
                return (
                    <TableCell component="th" scope="row" key={`cell_${idx}`} onClick={() => nameSort(c, c.headerName)}>
                        {c.headerName}
                        {c.sort && (sortDir === 'DESC' && sortDirName === c.field) && <ArrowUpwardIcon className={'icon'} />}
                        {c.sort && (sortDir === 'ASC' || sortDirName !== c.field) && <ArrowDownwardIcon className={'icon'} />}
                    </TableCell>
                );
            })}
        </TableRow>
    </TableHead>
    );
};