import { useEffect } from 'react';
import { goToDashboard } from '../../store/nav';
import useAuth from '../../hooks/useAuth';
import { goTo } from '../../store/nav';
import './index.css';

const LOGIN_INIT = `${window.config.authUrl}/init`;

const Login = ({ 
  dialog,
  LOCALE
}) => {
  const { authenticated } = useAuth({ auth: false, path: '/' });
  
  useEffect(() => {
    dialog.setCls('');
    dialog.setIcon('');
    dialog.setTitle(LOCALE.LOGIN.TITLE);
    dialog.setText(LOCALE.LOGIN.TEXT);
    dialog.setYesText(LOCALE.LOGIN.YES_TEXT);
    dialog.setYesCallback(() => {
      goTo(LOGIN_INIT);
    });
    dialog.setNoText('');
    dialog.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if (authenticated) {
      goToDashboard();
    }

  }, [authenticated]);

  return (
    <>
    </>
  );
}

export default Login;