import React, { useEffect } from 'react';
import {
    goToLogin
} from '../../store/nav';
import './index.css';

const LoggedOut = () => {
    useEffect(() => {
        goToLogin();
    }, []);

    return (
        <div>
            You have been logged out
        </div>
    );
}

export default LoggedOut;