import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { ExpandedTableHeader } from '../../components/ExpandedTableHeader';
import { ExpandedTableRow } from '../../components/ExpandedTableRow';
import { Search } from '../../components/Search';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import useSdk from '../../hooks/useSdk';
import { ROLES } from '../../store/roles';
import { 
    goToAssetEditPending, 
    goToAssetEdit,
    goToSharedWithStatus 
} from '../../store/nav';
import moment from 'moment';
import './index.css';
import 'jsoneditor/dist/jsoneditor.css';
import useAuth from '../../hooks/useAuth';

const createdCompare = (v1, v2) => {
    return new Date(v1).getTime() - new Date(v2).getTime();
}

const assetTabs = [
    { label: "Approved" },
    { label: "Review" },
    { label: "Draft" }
];

const Shared = ({ 
    dialog, 
    status, 
    LOCALE,
    clientId,
    roles
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [data, setData] = useState([]);
    const [dataType, setDataType] = useState(0);
    const [activeData, setActiveData] = useState([]);
    const [draftData, setDraftData] = useState([]);
    const [approvedData, setApprovedData] = useState([]);
    const [reviewData, setReviewData] = useState([]);
    const [dataObj, setDataObj] = useState([]);
    const [searchTerm, setSearchTerm] = useState();
    const [metaData, setMetaData] = useState();
    const [metaDataObj, setMetaDataObj] = useState();

    const [sortDir, setSortDir] = useState("ASC");
    const [sortDirName, setSortDirName] = useState("");

    const { sdk, sdkInitialized } = useSdk();

    useEffect(() => {
        loadTab();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, data, searchTerm, sortDir]);

    useEffect(() => {
        if (sdkInitialized) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sdkInitialized]);

    useEffect(() => {
        const orig = formatForList(metaData);
        if (searchTerm) {
            setMetaDataObj(orig.filter(d => d.name.toLowerCase().includes(searchTerm.toLowerCase())));
        } else {
            setMetaDataObj(orig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const loadTab = () => {
        const pathArray = location.pathname.split('/');
        const status = pathArray[pathArray.length - 1];
        let idx = assetTabs.findIndex(t => t.label.toLowerCase() === status);
        if (idx < 0) {
            idx = 0;
        }
        setDataType(idx);
        switch(dataType) {
            case 0:
                const defaultFilteredDrafts = removeDuplicates(approvedData);
                setDataObj(defaultFilteredDrafts);
                break; // Approved
            case 1: 
                setDataObj(filterSearch(reviewData));
                break; // Review
            case 2: 
                const filteredDrafts = removeDuplicates(draftData);
                setDataObj(filteredDrafts);
                break; // Draft
            default:
                const filteredApproved = removeDuplicates(approvedData);
                setDataObj(filteredApproved);
                break; // Approved
        }
    }

    const removeDuplicates = (arr) => {
        arr.sort((a, b) => parseInt(a.assetVersion) < parseInt(b.assetVersion) ? 1 : -1);
        const list = arr.filter((value, index, self) => {
            return index === self.findIndex((t) => (
                t.assetName === value.assetName
            ))
        });

        return filterSearch(list);
    }

    const filterSearch = (list) => {
        if (searchTerm) {
            return list.filter(d => d.assetName.toLowerCase().includes(searchTerm.toLowerCase())).sort(sortByName);
        } else {
            return list.sort(sortByName);
        }
    }

    const handleAssetEdit = ({ assetName, assetVersion }) => {
        goToAssetEditPending(assetName, assetVersion);
    };

    const handleAssetMetaDataEdit = ({ name, templateList }) => {
        const version = templateList[0].assetActiveVersion || 1;
        goToAssetEdit(name, version);
    };

    const formatForList = (data) => {
        const shared = [];
        for (let i in data) {
            shared.push({
                name: i,
                count: data[i].length-1,
                templateList: data[i]
            });
        }

        return shared;
    }

    const getData = async () => {
        try {
            
            status.open();

            const { data: { assetsMetaData } } = await sdk.assetListClientidGet({ clientId });
            setMetaData(assetsMetaData);
            setMetaDataObj(formatForList(assetsMetaData));

            const { data: { assetDataSet: allDataList } } = await sdk.assetStatusListClientidStatusGet({ clientId, status: 'ALL' });
            setDraftData(allDataList.filter(d => d.assetStatus === 'DRAFT'));
            setApprovedData(allDataList.filter(d => d.assetStatus === 'APPROVED'));
            setReviewData(allDataList.filter(d => d.assetStatus === 'REVIEW'));

            setActiveData(assetsMetaData);
            setData(assetsMetaData);
            
            status.close();
        }
        catch (e) {
            openErrorDialog(LOCALE.SHARED.ERROR);
        }
    }

    const openErrorDialog = (text) => {
        dialog.setTitle(LOCALE.SHARED.ERROR_TITLE);
        dialog.setText(text);
        dialog.setNoText('');
        dialog.setYesText(LOCALE.GLOBAL.OK);
        dialog.open();
    };

    const openReviewDialog = ({ name }) => {
        dialog.setTitle(name);
        dialog.setText(LOCALE.TEMPLATE.CREATE.ERROR);
        dialog.setNoText('');
        dialog.setYesText(LOCALE.GLOBAL.OK);
        dialog.open();
    };

    const getDate = ({ row: { timestamp: { epochSecond } } }) => {
        return moment.unix(epochSecond).format('LLL');
    }
    
    const getActions = ({ row }) => {
        return <div>
            <Button variant={'contained'} className='grid-button' onClick={() => handleAssetEdit(row)}>
                <VisibilityIcon className={'icon'} />
                {LOCALE.SHARED.REVIEW}
            </Button>
            <Button variant={'contained'} className='grid-button' onClick={() => openReviewDialog(row)}>
                <CheckIcon className={'icon'} />
                {LOCALE.SHARED.REVIEW}
            </Button>
        </div>;
    }
    
    const getMetaDataActions = ({ row }) => {
        return <div>
            <Button variant={'contained'} className='grid-button' onClick={() => handleAssetMetaDataEdit(row)}>
                <EditIcon className={'icon'} />
                {roles === ROLES.VIEWER ? LOCALE.SHARED.VIEW : LOCALE.SHARED.EDIT}
            </Button>
        </div>;
    }

    const sortByName = (a, b) => {
        if (sortDir === "ASC") {
            return (a[sortDirName]?.toString().toLowerCase() > b[sortDirName]?.toString().toLowerCase()) ? 0 : -1;
        }
        else {
            return (a[sortDirName]?.toString().toLowerCase() < b[sortDirName]?.toString().toLowerCase()) ? 0 : -1;
        }
    }
    
    const columns = [
        { field: 'assetName', headerName: LOCALE.SHARED.NAME, flex: 1, sort: true },
        { field: 'author', headerName: LOCALE.SHARED.AUTHOR, flex: 1, sort: true },
        { field: 'assetVersion', headerName: LOCALE.SHARED.VERSION, flex: 1 },
        { field: 'assetStatus', headerName: LOCALE.SHARED.STATUS, flex: 1 },
        { field: 'timestamp', headerName: LOCALE.SHARED.CREATED, valueGetter: getDate, sortComparator: createdCompare, flex: 1, sort: true },
        { field: 'actions', headerName: '', flex: 1, renderCell: getActions, align: 'right' }
    ];
    
    const metaDataColumns = [
        { field: 'name', headerName: LOCALE.SHARED.NAME, flex: 1 },
        { field: 'count', headerName: LOCALE.SHARED.COUNT, flex: 1 },
        { field: 'actions', headerName: '', flex: 1, renderCell: getMetaDataActions, align: 'right' }
    ];

    const setDataGrouping = (e, newValue) => {
        goToSharedWithStatus(navigate, assetTabs[newValue].label.toLowerCase());
        setDataType(newValue);
    }
    
    return (
        <>
            <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={'tabs'}>
                    <Tabs value={dataType} onChange={setDataGrouping} aria-label="Data Tabs">
                        {assetTabs.map((t, idx) => <Tab key={`tab_${idx}`} label={t.label} />)}
                    </Tabs>
                </Box>
                <div className={'search-container'}>
                    <Search
                        label={LOCALE.ASSET.SEARCH}
                        value={searchTerm}
                        onChange={ev => setSearchTerm(ev.target.value)}
                    />
                </div>
            </div>
            
            <div className={'content'}>
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <ExpandedTableHeader reviewColumn={1} {...{columns, dataType, sortDir, setSortDir, sortDirName, setSortDirName}} />
                            {dataObj.map((d, idx) => <ExpandedTableRow 
                                key={`row_${idx}`}
                                getData={getData}
                                clientId={clientId} 
                                sdk={sdk} 
                                data={d} 
                                LOCALE={LOCALE} 
                                dialog={dialog} 
                                roles={roles} 
                                status={status}
                                type={'asset'}
                                showExpand={dataType !== 1}
                                draftData={draftData}
                                approvedData={approvedData}
                                reviewData={reviewData}
                                ></ExpandedTableRow>)}
                        </Table>
                    </TableContainer>
                </div>

            </div>
        </>
    );
}

export default Shared;
