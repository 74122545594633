import React, { useEffect, useState } from 'react';
import { Nav } from '@ecare/pe-ui-components-library';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import useAuth from '../../hooks/useAuth';
import useDialog from '../../hooks/useDialog';
import useDrawer from '../../hooks/useDrawer';
import useStatus from '../../hooks/useStatus';
import './index.css';
import logo from '../../assets/logo-white.png';
import { AppVersion } from '../../components/AppVersion';
import { Header } from '../../components/Header';
import { roleFilter } from '../../store/util';

const defaultNavWidth = 250;
const defaultNavWidthCollapsed = 90;
const headerHeight = 58;

const Base = ({ 
  children, 
  auth, 
  path, 
  LOCALE, 
  changeLanguage, 
  routes, 
  process 
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [navWidth, setNavWidth] = useState(defaultNavWidth);

  const [dimensions, setDimensions] = useState({
    childContainerHeight: window.innerHeight - headerHeight,
    childContainerWidth: window.innerWidth - navWidth
  });

  const showLanguages = false;
  
  const { drawer } = useDrawer({});
  const { dialog } = useDialog({ LOCALE });
  const { status } = useStatus({});

  const { 
    authenticated, 
    sessionId, 
    userName, 
    roles, 
    userType,
    clientId,
    author,
    setClientIdValue,
    multiClientId
  } = useAuth({ auth, path, dialog });

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // This is only needed when handleResize is called from the window event
    window.navWidth = navWidth;
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navWidth]);

  const handleResize = () => {
    const finalNavWidth = window.navWidth || navWidth;
    setDimensions({
      childContainerHeight: window.innerHeight - headerHeight,
      childContainerWidth: window.innerWidth - finalNavWidth
    });
  };

  const collapseSide = () => {
    setNavWidth(defaultNavWidthCollapsed);
    setCollapsed(true);
  };

  const expandSide = () => {
    setNavWidth(defaultNavWidth);
    setCollapsed(false);
  };

  const renderChildren = React.Children.map(children, child => React.cloneElement(child, { 
    drawer, 
    dialog, 
    status, 
    LOCALE, 
    sessionId, 
    clientId,
    author,
    roles 
  }));

  const yesClose = () => {
    dialog.yesCallback();
    dialog.close();
  }

  const unsyncedClose = () => {
    dialog.unsyncedCallback();
    dialog.close();
  }

  const noClose = () => {
    dialog.noCallback();
    dialog.close();
  }


  return (
    <>
      <Box className={'base-view'}>
          <Box 
            className={collapsed ? 'left-collpased' : 'left'} 
            style={{width: navWidth, maxWidth: navWidth, minWidth: navWidth}}
          >

            {collapsed && <Box className='close-icon'>
              <ChevronRightIcon onClick={expandSide} />
              </Box>}
            {!collapsed && <Box className='close-icon'>
              <ChevronLeftIcon onClick={collapseSide} />
              </Box>}

            <div className={"icon"}>
              <img src={logo} alt={''} />
              {!collapsed && <div className={'logo-text'}>Message Editor</div>}
            </div>

            <hr />

            <Nav data={roleFilter(routes, roles)} authenticated={authenticated} />
          </Box>
          <Box className={'right'}>

            <Header {...{
              authenticated, LOCALE, 
              dialog, changeLanguage, showLanguages,
              userName, roles, 
              clientId, setClientIdValue, userType, multiClientId
              }}
              />

            <Box className={'child-container'} style={{width: dimensions.childContainerWidth, height: dimensions.childContainerHeight}}>
              {renderChildren}
            </Box>

          </Box>
          <AppVersion />
      </Box>
      
      <Dialog
        open={status.isOpen}
        onClose={status.close}
      >
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      
      <Dialog
        open={dialog.isOpen}
        onClose={dialog.close}
      >
        <DialogTitle>
          {dialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={dialog.cls}>
            {dialog.icon}
            {dialog.text}
          </DialogContentText>
          <br />
          {dialog.renderDialogComponents()}

        </DialogContent>
        <DialogActions>
          {dialog.noText && <Button onClick={noClose} variant='contained' onMouseOver={e => e.target.style.background = 'red'}  sx={{ color: 'white', backgroundColor: 'red'}}>
            {dialog.noText}
          </Button>}
          {dialog.yesText && <Button disabled={dialog.isDisabled} onClick={yesClose} autoFocus variant='contained' onMouseOver={e => e.target.style.background = '#5C3883'} sx={{ color: 'white', backgroundColor: '#5C3883'}}>
            {dialog.yesText}
          </Button>}
          {dialog.unsyncedText && <Button onClick={unsyncedClose} autoFocus variant='contained' onMouseOver={e => e.target.style.background = '#5C3883'} sx={{ color: 'white', backgroundColor: '#5C3883'}}>
            {dialog.unsyncedText}
          </Button>}
        </DialogActions>
      </Dialog>
      
      <Drawer 
        anchor={drawer.anchor} 
        open={drawer.isOpen}
        >
        <Box justifyContent={'right'} alignContent={'right'} display={'flex'}>
          <CloseIcon onClick={drawer.close} />
        </Box>
        {drawer.renderChildren}
      </Drawer>
    </>
  );
}

export default Base;
