import { useState } from 'react';

const useDrawer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchor, setAnchor] = useState('right');
    const [size, setSize] = useState('20%');
    const [renderChildren, setChildren] = useState(() => {});
    
    const open = () => {
        setIsOpen(true);
    };
    
    const close = () => {
        setIsOpen(false);
    };
    
    return {
        drawer: {
            open,
            isOpen,
            anchor,
            setAnchor,
            renderChildren,
            setChildren,
            size,
            setSize,
            close
        }
    }
};

export default useDrawer;